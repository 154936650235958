/* THIS IS A GENERATED FILE - DO NOT MODIFY */
/* eslint-disable */
import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The `Byte` scalar type represents non-fractional whole numeric values. Byte can represent values between 0 and 255. */
  Byte: { input: number; output: number; }
  ByteArray: { input: any; output: any; }
  /** The `Date` scalar represents an ISO-8601 compliant date type. */
  Date: { input: Date; output: Date; }
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: { input: Date; output: Date; }
  /** The built-in `Decimal` scalar type. */
  Decimal: { input: number; output: number; }
  /** Represents an instant on the global timeline, with nanosecond resolution. */
  Instant: { input: any; output: any; }
  /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: { input: number; output: number; }
  /** The `Short` scalar type represents non-fractional signed whole 16-bit numeric values. Short can represent values between -(2^15) and 2^15 - 1. */
  Short: { input: number; output: number; }
  UUID: { input: any; output: any; }
  Url: { input: string; output: string; }
  Uuid: { input: string; output: string; }
};

export type Account = {
  __typename?: 'Account';
  /** (Required Permissions: hxp.account-app.read) */
  accountApp?: Maybe<AccountApp>;
  /** (Required Permissions: hxp.account-app.read) */
  accountAppByKey?: Maybe<AccountApp>;
  /** (Required Permissions: hxp.account-app.read) */
  accountApps: Array<AccountApp>;
  auditEvents?: Maybe<AuditEventsConnection>;
  contract?: Maybe<Contract>;
  contracts: Array<Contract>;
  /** (Required Permissions: hxp.environment.read) */
  environment?: Maybe<Environment>;
  /** (Required Permissions: hxp.environment.read) */
  environments: Array<Environment>;
  /** Retrieve assigned federation email domain rules in an Account */
  federationEmailDomainRules: Array<FederationEmailDomainRule>;
  /** Retrieve a list of federation providers */
  federationProviders: Array<FederationProvider>;
  /** (Required Permissions: iam.usergroup.list) */
  groups: Array<UserGroup>;
  /** (Required Permissions: hxp.account.read) */
  hsiNumber?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  /** Retrieve JavaScriptClients in an Account (Required Permissions: iam.account-client.list) */
  javaScriptClients: Array<JavaScriptClient>;
  /** Retrieve MFA settings for an Account (Required Permissions: iam.mfa.read) */
  mfaSettings: MfaSettings;
  /** (Required Permissions: hxp.account.read) */
  name: Scalars['String']['output'];
  /** (Required Permissions: iam.user.list) */
  paginatedUsers?: Maybe<PaginatedUsersConnection>;
  /** Retrieve ServiceClients in an Account (Required Permissions: iam.account-client.list) */
  serviceClients: Array<ServiceClient>;
  /** Retrieve Paginated Service Clients in an Account (Required Permissions: iam.account-client.list) */
  serviceClientsPaginated?: Maybe<ServiceClientsPaginatedConnection>;
  /** (Required Permissions: hxp.account.read) */
  sourceAccountId: Scalars['String']['output'];
  /** (Required Permissions: hxp.account.read) */
  sourceAccountSystem: SourceSystem;
  /** (Required Permissions: hxp.subscribed-app.read) */
  subscribedApp?: Maybe<SubscribedApp>;
  subscription?: Maybe<SubscriptionDetail>;
  subscriptions: Array<SubscriptionDetail>;
  /** Retrieve custom user attributes for an Account */
  userAttributes?: Maybe<UserAttributesConnection>;
  /** Retrieve limited information regarding a user. */
  userSummaries: Array<UserSummary>;
  /** (Required Permissions: iam.user.list) */
  users: Array<User>;
  /** Retrieve WebServerClients in an Account (Required Permissions: iam.account-client.list) */
  webServerClients: Array<WebServerClient>;
  /** Retrieve Paginated Web Server Clients in an Account (Required Permissions: iam.account-client.list) */
  webServerClientsPaginated?: Maybe<WebServerClientsPaginatedConnection>;
};


export type AccountAccountAppArgs = {
  id: Scalars['ID']['input'];
};


export type AccountAccountAppByKeyArgs = {
  appKey: Scalars['String']['input'];
};


export type AccountAuditEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Instant']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<AuditEventSortInput>>;
  startDate?: InputMaybe<Scalars['Instant']['input']>;
};


export type AccountContractArgs = {
  contractId: Scalars['ID']['input'];
};


export type AccountEnvironmentArgs = {
  environmentId: Scalars['ID']['input'];
};


export type AccountFederationEmailDomainRulesArgs = {
  where?: InputMaybe<HrdEmailDomainRuleFilterInput>;
};


export type AccountFederationProvidersArgs = {
  where?: InputMaybe<FederationProviderFilterInput>;
};


export type AccountGroupsArgs = {
  where?: InputMaybe<UserGroupFilterInput>;
};


export type AccountJavaScriptClientsArgs = {
  where?: InputMaybe<JavaScriptClientFilter>;
};


export type AccountPaginatedUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  include?: InputMaybe<UserIncludeInput>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<UserSortInput>>;
  where?: InputMaybe<UserFilterInput>;
};


export type AccountServiceClientsArgs = {
  where?: InputMaybe<ServiceClientFilter>;
};


export type AccountServiceClientsPaginatedArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<ServiceClientSortInput>>;
  where?: InputMaybe<ServiceClientFilter>;
};


export type AccountSubscribedAppArgs = {
  id: Scalars['ID']['input'];
};


export type AccountSubscriptionArgs = {
  id: Scalars['ID']['input'];
};


export type AccountUserAttributesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<UserAttributeSortInput>>;
  where?: InputMaybe<UserAttributeFilterInput>;
};


export type AccountUserSummariesArgs = {
  include?: InputMaybe<UserIncludeInput>;
  where?: InputMaybe<UserSummaryFilterInput>;
};


export type AccountUsersArgs = {
  include?: InputMaybe<UserIncludeInput>;
  where?: InputMaybe<UserFilterInput>;
};


export type AccountWebServerClientsArgs = {
  where?: InputMaybe<WebServerClientFilter>;
};


export type AccountWebServerClientsPaginatedArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<WebServerClientSortInput>>;
  where?: InputMaybe<WebServerClientFilter>;
};

export type AccountApp = {
  __typename?: 'AccountApp';
  account: Account;
  accountId: Scalars['ID']['output'];
  accountKey: Scalars['String']['output'];
  app: App;
  appKey: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  launchUrl: Scalars['String']['output'];
  provisioningStatus: AccountAppProvisioningStatus;
  /** (Required Permissions: iam.role.list) */
  roles: Array<AccountAppRole>;
  /**
   * Use provisioningStatus in place of this value, enum order is preserved.
   * @deprecated No longer supported
   */
  status: ProvisioningStatus;
};

export enum AccountAppProvisioningStatus {
  Decommissioned = 'DECOMMISSIONED',
  Decommissioning = 'DECOMMISSIONING',
  Pending = 'PENDING',
  Provisioned = 'PROVISIONED',
  Provisioning = 'PROVISIONING'
}

/** A set of roles made available through an application. */
export type AccountAppRole = {
  __typename?: 'AccountAppRole';
  role: Role;
  /** Set of usergroups that have been assigned the role in account context for the application. (Required Permissions: iam.roleusergroup.read) */
  userGroups: Array<UserGroup>;
  /** Set of users that have been assigned the role in account context for the application. (Required Permissions: iam.roleuser.read, iam.user.list) */
  users: Array<User>;
};


/** A set of roles made available through an application. */
export type AccountAppRoleUsersArgs = {
  include?: InputMaybe<UserIncludeInput>;
  where?: InputMaybe<UserFilterInput>;
};

export type AccountClientDeleteInput = {
  clientId: Scalars['String']['input'];
};

export type AccountClientScope = {
  __typename?: 'AccountClientScope';
  scope: Scalars['String']['output'];
};

export type AccountClientSecretResetInput = {
  clientId: Scalars['String']['input'];
};

export type AccountClientSecretResetOutput = {
  __typename?: 'AccountClientSecretResetOutput';
  secretValue: Scalars['String']['output'];
};

export type AccountFilter = {
  AND?: InputMaybe<Array<AccountFilter>>;
  OR?: InputMaybe<Array<AccountFilter>>;
  hsiNumber?: InputMaybe<Scalars['Long']['input']>;
  id?: InputMaybe<Scalars['Uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  sourceAccountId?: InputMaybe<Scalars['String']['input']>;
  sourceAccountSystem?: InputMaybe<SourceSystem>;
  termAcceptedDateTime?: InputMaybe<Scalars['Instant']['input']>;
};

export type Actor = {
  __typename?: 'Actor';
  id: Scalars['String']['output'];
  type: UserAuditType;
};

export type ActorSortInput = {
  id?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
};

export type Allotment = IPricingStrategy & {
  __typename?: 'Allotment';
  allotmentSize?: Maybe<Scalars['Int']['output']>;
  cycleCount: Scalars['Int']['output'];
  cyclePeriod: UsageCyclePeriod;
  cyclePeriodEndDate: Scalars['Instant']['output'];
  cyclePeriodStartDate: Scalars['Instant']['output'];
  overageCycleCount?: Maybe<Scalars['Int']['output']>;
  overageCyclePeriod?: Maybe<UsageCyclePeriod>;
  overageCyclePeriodEndDate?: Maybe<Scalars['Instant']['output']>;
  overageCyclePeriodStartDate?: Maybe<Scalars['Instant']['output']>;
};

export type App = {
  __typename?: 'App';
  appType: AppType;
  iconUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  localizedDescription: Scalars['String']['output'];
  localizedName: Scalars['String']['output'];
  urlExpression: Scalars['String']['output'];
  usageTypes?: Maybe<Array<Maybe<UsageType>>>;
};

/** A connection to a list of items. */
export type AppConnection = {
  __typename?: 'AppConnection';
  /** A list of edges. */
  edges?: Maybe<Array<AppEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<App>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AppEdge = {
  __typename?: 'AppEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: App;
};

export type AppFilter = {
  AND?: InputMaybe<Array<AppFilter>>;
  OR?: InputMaybe<Array<AppFilter>>;
  id?: InputMaybe<Scalars['Uuid']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  key_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export enum AppType {
  Account = 'ACCOUNT',
  Config = 'CONFIG',
  Dynamic = 'DYNAMIC',
  Service = 'SERVICE',
  Standard = 'STANDARD',
  Template = 'TEMPLATE'
}

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER'
}

export type AppsClientError = {
  __typename?: 'AppsClientError';
  code?: Maybe<Scalars['String']['output']>;
  field?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type AttributeValueAssignmentsConnection = {
  __typename?: 'AttributeValueAssignmentsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<AttributeValueAssignmentsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<UserAttributeValueAssignment>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type AttributeValueAssignmentsEdge = {
  __typename?: 'AttributeValueAssignmentsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<UserAttributeValueAssignment>;
};

export type AuditEvent = {
  __typename?: 'AuditEvent';
  actor: Actor;
  customData?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  message?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  object?: Maybe<AuditObject>;
  relatedObject?: Maybe<AuditObject>;
  reportingAppKey?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['Instant']['output'];
  type: Scalars['String']['output'];
};

export type AuditEventSortInput = {
  actor?: InputMaybe<ActorSortInput>;
  id?: InputMaybe<SortEnumType>;
  object?: InputMaybe<AuditObjectSortInput>;
  relatedObject?: InputMaybe<AuditObjectSortInput>;
  reportingAppKey?: InputMaybe<SortEnumType>;
  timestamp?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type AuditEventsConnection = {
  __typename?: 'AuditEventsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<AuditEventsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<AuditEvent>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type AuditEventsEdge = {
  __typename?: 'AuditEventsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: AuditEvent;
};

export type AuditObject = {
  __typename?: 'AuditObject';
  id?: Maybe<Scalars['String']['output']>;
  scope?: Maybe<Scalars['String']['output']>;
};

export type AuditObjectSortInput = {
  id?: InputMaybe<SortEnumType>;
  scope?: InputMaybe<SortEnumType>;
};

export type BillableUsage = {
  __typename?: 'BillableUsage';
  descriptionLocalizationKey?: Maybe<Scalars['String']['output']>;
  nameLocalizationKey?: Maybe<Scalars['String']['output']>;
  pricingStrategy: UsagePricingStrategy;
  strategy: IPricingStrategy;
  unitOfMeasure: UsageUnitOfMeasure;
};

export type BooleanFilterInput = {
  and?: InputMaybe<Array<BooleanFilterInput>>;
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<BooleanFilterInput>>;
};

export type Certificate = {
  __typename?: 'Certificate';
  friendlyName: Scalars['String']['output'];
  issuer: Scalars['String']['output'];
  notAfter: Scalars['Date']['output'];
  notBefore: Scalars['Date']['output'];
  subject: Scalars['String']['output'];
  thumbprint: Scalars['String']['output'];
};

export type ClientError = {
  __typename?: 'ClientError';
  code?: Maybe<Error>;
  field?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type ClientSecretMetadata = {
  __typename?: 'ClientSecretMetadata';
  createdOn: Scalars['DateTime']['output'];
};

export type ConsumptionComponent = {
  __typename?: 'ConsumptionComponent';
  consumptionSchedules: Array<ConsumptionSchedule>;
  cyclePeriod: CyclePeriod;
  id: Scalars['ID']['output'];
  overageCyclePeriod?: Maybe<CyclePeriod>;
  pricingStrategy: PricingStrategy;
  sourceLineItemId?: Maybe<Scalars['String']['output']>;
  subscription: SubscriptionDetail;
  usageTypeKey: Scalars['String']['output'];
};

export type ConsumptionSchedule = {
  __typename?: 'ConsumptionSchedule';
  consumptionComponent: ConsumptionComponent;
  endQuantity?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  pricingMethod: PricingMethod;
  startQuantity: Scalars['Int']['output'];
};

/** (Required Permissions: hxp.contract.read) */
export type Contract = {
  __typename?: 'Contract';
  account: Account;
  accountId: Scalars['ID']['output'];
  contractDate: Scalars['Instant']['output'];
  createdDate: Scalars['Instant']['output'];
  id: Scalars['ID']['output'];
  parentId?: Maybe<Scalars['ID']['output']>;
  requestCorrelationId: Scalars['String']['output'];
  sourceContractId?: Maybe<Scalars['String']['output']>;
  sourceContractSystem: SourceSystem;
  subscriptionDetail: SubscriptionDetail;
  subscriptionDetails: Array<SubscriptionDetail>;
  supportAccount: Account;
  supportAccountId: Scalars['ID']['output'];
};


/** (Required Permissions: hxp.contract.read) */
export type ContractSubscriptionDetailArgs = {
  subscriptionDetailId: Scalars['ID']['input'];
};

export type ContractAndAccount = {
  __typename?: 'ContractAndAccount';
  accountId: Scalars['ID']['output'];
  accountName: Scalars['String']['output'];
  contractCreatedDateTime: Scalars['Instant']['output'];
  contractId: Scalars['ID']['output'];
  contractState: ContractState;
  contractStatus: ContractStatus;
  contractType: ContractType;
  hsiNumber?: Maybe<Scalars['Long']['output']>;
  sourceAccountId: Scalars['String']['output'];
  sourceAccountSystem: SourceSystem;
  sourceContractId: Scalars['ID']['output'];
  sourceContractSystem: SourceSystem;
};

export type ContractAndAccountFilterInput = {
  accountName?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<ContractAndAccountFilterInput>>;
  contractCreatedDateTime?: InputMaybe<InstantFilterInput>;
  hsiNumber?: InputMaybe<LongFilterInput>;
  or?: InputMaybe<Array<ContractAndAccountFilterInput>>;
  sourceAccountId?: InputMaybe<StringFilterInput>;
  sourceAccountSystem?: InputMaybe<SourceSystemFilterInput>;
  sourceContractId?: InputMaybe<StringFilterInput>;
  sourceContractSystem?: InputMaybe<SourceSystemFilterInput>;
};

export type ContractAndAccountSortInput = {
  contractCreatedDateTime?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type ContractAndAccountViewConnection = {
  __typename?: 'ContractAndAccountViewConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ContractAndAccountViewEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<ContractAndAccount>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ContractAndAccountViewEdge = {
  __typename?: 'ContractAndAccountViewEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ContractAndAccount>;
};

export type ContractFilter = {
  AND?: InputMaybe<Array<ContractFilter>>;
  OR?: InputMaybe<Array<ContractFilter>>;
  id?: InputMaybe<Scalars['Uuid']['input']>;
  sourceContractId?: InputMaybe<Scalars['String']['input']>;
  sourceContractSystem?: InputMaybe<SourceSystem>;
};

/** HxP Contract Log */
export type ContractLog = {
  __typename?: 'ContractLog';
  contractId: Scalars['ID']['output'];
  eventDateTime: Scalars['Instant']['output'];
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export enum ContractState {
  Complete = 'COMPLETE',
  Error = 'ERROR',
  Processing = 'PROCESSING',
  Queued = 'QUEUED'
}

export enum ContractStatus {
  Adminemailsending = 'ADMINEMAILSENDING',
  Adminemailsent = 'ADMINEMAILSENT',
  Adminonboarded = 'ADMINONBOARDED',
  Createsubscription = 'CREATESUBSCRIPTION',
  Senttermsemail = 'SENTTERMSEMAIL',
  Subscriptiononboarded = 'SUBSCRIPTIONONBOARDED',
  Termsaccepted = 'TERMSACCEPTED'
}

/** HxP Subscription relating to a contract */
export type ContractSubscription = {
  __typename?: 'ContractSubscription';
  contractId: Scalars['ID']['output'];
  hxpEnvironmentId?: Maybe<Scalars['String']['output']>;
  hxpEnvironmentTitle?: Maybe<Scalars['String']['output']>;
  hxpSubscriptionId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  sourceSubscriptionId: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export enum ContractType {
  Amendment = 'AMENDMENT',
  Initialcontract = 'INITIALCONTRACT',
  Renewal = 'RENEWAL'
}

export type CurrentUser = {
  __typename?: 'CurrentUser';
  accountApp?: Maybe<CurrentUserAccountApp>;
  accountApps: Array<CurrentUserAccountApp>;
  displayName: Scalars['String']['output'];
  email: Scalars['String']['output'];
  groups: Array<CurrentUserUserGroup>;
  homeAccount: CurrentUserAccount;
  id: Scalars['ID']['output'];
  name: UserName;
  subscribedApp?: Maybe<CurrentUserSubscribedApp>;
  subscribedApps: Array<CurrentUserSubscribedApp>;
  userName: Scalars['String']['output'];
};


export type CurrentUserAccountAppArgs = {
  accountId: Scalars['ID']['input'];
  appKey: Scalars['String']['input'];
};


export type CurrentUserGroupsArgs = {
  where?: InputMaybe<CurrentUserUserGroupFilterInput>;
};


export type CurrentUserSubscribedAppArgs = {
  appKey: Scalars['String']['input'];
  environmentKey: Scalars['String']['input'];
};

export type CurrentUserAccount = {
  __typename?: 'CurrentUserAccount';
  account: Account;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type CurrentUserAccountApp = {
  __typename?: 'CurrentUserAccountApp';
  accountId: Scalars['ID']['output'];
  accountKey: Scalars['String']['output'];
  app: App;
  appKey: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  launchUrl: Scalars['String']['output'];
  /** Get permissions of current user */
  permissions: Array<CurrentUserPermissionGrant>;
  provisioningStatus: AccountAppProvisioningStatus;
  /** Get roles of current user */
  roles: Array<CurrentUserRoleGrant>;
  /**
   * Use provisioningStatus in place of this value, enum order is preserved.
   * @deprecated No longer supported
   */
  status: ProvisioningStatus;
};

export type CurrentUserEnvironment = {
  __typename?: 'CurrentUserEnvironment';
  accountId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type CurrentUserPermissionGrant = {
  __typename?: 'CurrentUserPermissionGrant';
  /** Unique permission key. */
  key: Scalars['String']['output'];
};

export type CurrentUserRoleGrant = {
  __typename?: 'CurrentUserRoleGrant';
  /** Unique role key. */
  key: Scalars['String']['output'];
};

export type CurrentUserSubscribedApp = {
  __typename?: 'CurrentUserSubscribedApp';
  accountId: Scalars['ID']['output'];
  app: App;
  appKey: Scalars['String']['output'];
  environment: CurrentUserEnvironment;
  environmentId: Scalars['ID']['output'];
  environmentKey: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  launchUrl?: Maybe<Scalars['String']['output']>;
  /** Get permissions of current user. */
  permissions: Array<CurrentUserPermissionGrant>;
  provisioningStatus: SubscribedAppProvisioningStatus;
  /** Get roles of current user. */
  roles: Array<CurrentUserRoleGrant>;
  /** @deprecated Please use provisioningStatus instead. */
  status: ProvisioningStatus;
};

export type CurrentUserUserGroup = {
  __typename?: 'CurrentUserUserGroup';
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type CurrentUserUserGroupFilterInput = {
  and?: InputMaybe<Array<CurrentUserUserGroupFilterInput>>;
  displayName?: InputMaybe<NameFilterInput>;
  id?: InputMaybe<UuidFilterInput>;
  or?: InputMaybe<Array<CurrentUserUserGroupFilterInput>>;
};

export enum CyclePeriod {
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Yearly = 'YEARLY'
}

/** Email */
export type Email = {
  __typename?: 'Email';
  /** Type of Email */
  type?: Maybe<Scalars['String']['output']>;
  /** Email Address */
  value?: Maybe<Scalars['String']['output']>;
};

/** Email */
export type EmailInput = {
  /** Type of Email */
  type?: InputMaybe<Scalars['String']['input']>;
  /** Email Address */
  value: Scalars['String']['input'];
};

export type EnumFilterInputTypeOfApplicationUserTypeFilterInput = {
  and?: InputMaybe<Array<EnumFilterInputTypeOfApplicationUserTypeFilterInput>>;
  eq?: InputMaybe<UserType>;
  or?: InputMaybe<Array<EnumFilterInputTypeOfApplicationUserTypeFilterInput>>;
};

export type Environment = {
  __typename?: 'Environment';
  account: Account;
  accountId: Scalars['ID']['output'];
  auditEvents?: Maybe<AuditEventsConnection>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
  subscribedApps: Array<SubscribedApp>;
  subscriptions: Array<SubscriptionDetail>;
};


export type EnvironmentAuditEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Instant']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<AuditEventSortInput>>;
  startDate?: InputMaybe<Scalars['Instant']['input']>;
};

/** A set of roles made available through a subscribed application. */
export type EnvironmentAppRole = {
  __typename?: 'EnvironmentAppRole';
  /** Set of users assigned to the role directly or via group membership in the environment context of the subscribed app. (Required Permissions: iam.roleuser.read, iam.user.list) */
  effectiveUsers: Array<User>;
  role: Role;
  /** Set of users that have been directly assigned the role in the environment context of the subscribed app. (Required Permissions: iam.roleuser.read, iam.user.list) */
  roleUsers: Array<User>;
  /** Set of user groups that have been assigned the role in the environment context of the subscribed app. (Required Permissions: iam.roleusergroup.read) */
  userGroups: Array<UserGroup>;
  /**
   * Set of users assigned to the role directly or via group membership in the environment context of the subscribed app. (Required Permissions: iam.roleuser.read, iam.user.list)
   * @deprecated The `users` field will be removed on 2025-04-01T23:59:59Z. Use the `effectiveUsers` field instead.
   */
  users: Array<User>;
};


/** A set of roles made available through a subscribed application. */
export type EnvironmentAppRoleEffectiveUsersArgs = {
  include?: InputMaybe<UserIncludeInput>;
  where?: InputMaybe<UserFilterInput>;
};


/** A set of roles made available through a subscribed application. */
export type EnvironmentAppRoleRoleUsersArgs = {
  include?: InputMaybe<UserIncludeInput>;
  where?: InputMaybe<UserFilterInput>;
};


/** A set of roles made available through a subscribed application. */
export type EnvironmentAppRoleUserGroupsArgs = {
  where?: InputMaybe<UserGroupFilterInput>;
};


/** A set of roles made available through a subscribed application. */
export type EnvironmentAppRoleUsersArgs = {
  include?: InputMaybe<UserIncludeInput>;
  where?: InputMaybe<UserFilterInput>;
};

export type EnvironmentAppRoleFilterInput = {
  and?: InputMaybe<Array<EnvironmentAppRoleFilterInput>>;
  or?: InputMaybe<Array<EnvironmentAppRoleFilterInput>>;
  roleKey?: InputMaybe<StringIdFilterInput>;
};

export enum Error {
  /** Indicates the resource already exists. */
  Alreadyexists = 'ALREADYEXISTS',
  /** An error updating one or more user claims. */
  Claimupdatefailed = 'CLAIMUPDATEFAILED',
  /** Indicates a concurrency failure occured. */
  Concurrencyfailure = 'CONCURRENCYFAILURE',
  /** An error confirming a user's email address. */
  Emailconfirmationfailed = 'EMAILCONFIRMATIONFAILED',
  /** Indicates that the system refused to send the email. Retrying will not succeed. */
  Emailfailed = 'EMAILFAILED',
  /** Email is not verified */
  Emailunverified = 'EMAILUNVERIFIED',
  /** Communication error with external system. */
  Externalcommunicationerror = 'EXTERNALCOMMUNICATIONERROR',
  /** Indicates that resource state already matches the requested state. */
  Ignored = 'IGNORED',
  /** Invalid token error */
  Invalidtoken = 'INVALIDTOKEN',
  /** Indicates the resource is locked. */
  Locked = 'LOCKED',
  /** Indicates an external login is already associated with a user. */
  Loginalreadyassociated = 'LOGINALREADYASSOCIATED',
  /** Indicates the requested resource could not be found. */
  Notfound = 'NOTFOUND',
  /** Indicates the operation is not supported. */
  Notsupported = 'NOTSUPPORTED',
  /** Indicates a recovery code was not redeemed. */
  Recoverycoderedemptionfailed = 'RECOVERYCODEREDEMPTIONFAILED',
  /** Failed to remove user's password */
  Removepasswordfailed = 'REMOVEPASSWORDFAILED',
  /** Failed to set user's password */
  Setpasswordfailed = 'SETPASSWORDFAILED',
  /** Active subscription was not found. */
  Subscriptioninvalid = 'SUBSCRIPTIONINVALID',
  /** Indicates that the user is not authorized to perform the requested operation. */
  Unauthorized = 'UNAUTHORIZED',
  /** Indicates a user already has a password set. */
  Useralreadyhaspassword = 'USERALREADYHASPASSWORD',
  /** Indicates one or more user groups exist in both the add and remove lists. */
  Usergroupexistsinbothaddandremove = 'USERGROUPEXISTSINBOTHADDANDREMOVE',
  /** Indicates lockout is not enabled for the specified user. */
  Userlockoutnotenabled = 'USERLOCKOUTNOTENABLED',
  /** An error updating a user record. */
  Userupdatefailed = 'USERUPDATEFAILED',
  /** An error validating inputs. */
  Validation = 'VALIDATION',
  /** Indicates a version mismatch */
  Versionmismatch = 'VERSIONMISMATCH'
}

/** (Required Permissions: iam.federation-email-domain-rule.read) */
export type FederationEmailDomainRule = {
  __typename?: 'FederationEmailDomainRule';
  account: Account;
  domain: Scalars['String']['output'];
  enableLocalLogin: Scalars['Boolean']['output'];
  key: Scalars['ID']['output'];
};

export type FederationEmailDomainRuleCreateInput = {
  domain: Scalars['String']['input'];
  enableLocalLogin: Scalars['Boolean']['input'];
};

export type FederationEmailDomainRuleDeleteInput = {
  key: Scalars['ID']['input'];
};

export type FederationEmailDomainRuleEditInput = {
  domain?: InputMaybe<Scalars['String']['input']>;
  enableLocalLogin?: InputMaybe<Scalars['Boolean']['input']>;
  key: Scalars['ID']['input'];
};

/** (Required Permissions: iam.federation-provider.read) */
export type FederationProvider = {
  __typename?: 'FederationProvider';
  account: Account;
  /** Claims for the federation provider */
  claims?: Maybe<FederationProviderClaims>;
  displayName: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  /** Assigned federation email domain rules */
  federationEmailDomainRules?: Maybe<Array<FederationEmailDomainRule>>;
  providerId: Scalars['ID']['output'];
  providerType: ProviderType;
  /** SAML configuration for the federation provider, if it's a SAML provider type */
  samlConfiguration?: Maybe<SamlConfiguration>;
  /** Configured custom user attributes */
  userAttributeAssignments: Array<FederationProviderUserAttributeAssignment>;
};


/** (Required Permissions: iam.federation-provider.read) */
export type FederationProviderUserAttributeAssignmentsArgs = {
  where?: InputMaybe<FederationProviderUserAttributeAssignmentFilterInput>;
};

export type FederationProviderClaims = {
  __typename?: 'FederationProviderClaims';
  emailClaim: Scalars['String']['output'];
  externalUserIdClaim: Scalars['String']['output'];
  firstNameClaim?: Maybe<Scalars['String']['output']>;
  groupClaim?: Maybe<Scalars['String']['output']>;
  lastNameClaim?: Maybe<Scalars['String']['output']>;
  userNameClaim?: Maybe<Scalars['String']['output']>;
};

export type FederationProviderClaimsCreateInput = {
  emailClaim: Scalars['String']['input'];
  externalUserIdClaim: Scalars['String']['input'];
  firstNameClaim?: InputMaybe<Scalars['String']['input']>;
  groupClaim?: InputMaybe<Scalars['String']['input']>;
  lastNameClaim?: InputMaybe<Scalars['String']['input']>;
  userNameClaim?: InputMaybe<Scalars['String']['input']>;
};

export type FederationProviderClaimsPatchInput = {
  emailClaim?: InputMaybe<Scalars['String']['input']>;
  externalUserIdClaim?: InputMaybe<Scalars['String']['input']>;
  firstNameClaim?: InputMaybe<Scalars['String']['input']>;
  groupClaim?: InputMaybe<Scalars['String']['input']>;
  lastNameClaim?: InputMaybe<Scalars['String']['input']>;
  userNameClaim?: InputMaybe<Scalars['String']['input']>;
};

export type FederationProviderCreateInput = {
  claims: FederationProviderClaimsCreateInput;
  displayName: Scalars['String']['input'];
  enabled: Scalars['Boolean']['input'];
  providerType: ProviderType;
  userAttributeAssignments?: InputMaybe<Array<FederationProviderUserAttributeAssignmentInput>>;
};

export type FederationProviderDeleteInput = {
  providerId: Scalars['ID']['input'];
};

export type FederationProviderEmailDomainRulesUpdateInput = {
  addEmailDomainRules?: InputMaybe<IdListPatchFilterInput>;
  providerId: Scalars['ID']['input'];
  removeEmailDomainRules?: InputMaybe<IdListPatchFilterInput>;
};

export type FederationProviderFilterInput = {
  and?: InputMaybe<Array<FederationProviderFilterInput>>;
  displayName?: InputMaybe<NameFilterInput>;
  or?: InputMaybe<Array<FederationProviderFilterInput>>;
  providerId?: InputMaybe<UuidFilterInput>;
};

export type FederationProviderPatchInput = {
  addUserAttributeAssignments?: InputMaybe<Array<FederationProviderUserAttributeAssignmentInput>>;
  claims?: InputMaybe<FederationProviderClaimsPatchInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  patchUserAttributeAssignments?: InputMaybe<Array<FederationProviderUserAttributeAssignmentPatchInput>>;
  providerId: Scalars['ID']['input'];
  removeUserAttributeKeys?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** (Required Permissions: iam.federation-provider-user-attribute.read) */
export type FederationProviderUserAttributeAssignment = {
  __typename?: 'FederationProviderUserAttributeAssignment';
  sourceClaimType: Scalars['String']['output'];
  userAttribute: UserAttribute;
};

export type FederationProviderUserAttributeAssignmentFilterInput = {
  and?: InputMaybe<Array<FederationProviderUserAttributeAssignmentFilterInput>>;
  or?: InputMaybe<Array<FederationProviderUserAttributeAssignmentFilterInput>>;
  sourceClaimType?: InputMaybe<NormalizedStringFilterInput>;
  userAttribute?: InputMaybe<UserAttributeFilterInput>;
};

export type FederationProviderUserAttributeAssignmentInput = {
  sourceClaimType: Scalars['String']['input'];
  userAttributeKey: Scalars['String']['input'];
};

export type FederationProviderUserAttributeAssignmentPatchInput = {
  sourceClaimType?: InputMaybe<Scalars['String']['input']>;
  userAttributeKey: Scalars['String']['input'];
};

export type HrdEmailDomainRuleFilterInput = {
  and?: InputMaybe<Array<HrdEmailDomainRuleFilterInput>>;
  domain?: InputMaybe<NormalizedStringFilterInput>;
  key?: InputMaybe<UuidFilterInput>;
  or?: InputMaybe<Array<HrdEmailDomainRuleFilterInput>>;
};

export type IPricingStrategy = {
  cycleCount: Scalars['Int']['output'];
  cyclePeriod: UsageCyclePeriod;
  cyclePeriodEndDate: Scalars['Instant']['output'];
  cyclePeriodStartDate: Scalars['Instant']['output'];
};

export type IdListPatchFilterInput = {
  where: IdListPatchFilterOperationsInput;
};

export type IdListPatchFilterOperationsInput = {
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type InstantFilterInput = {
  and?: InputMaybe<Array<InstantFilterInput>>;
  gt?: InputMaybe<Scalars['Instant']['input']>;
  lt?: InputMaybe<Scalars['Instant']['input']>;
  or?: InputMaybe<Array<InstantFilterInput>>;
};

export type JavaScriptClient = {
  __typename?: 'JavaScriptClient';
  clientId: Scalars['String']['output'];
  clientName?: Maybe<Scalars['String']['output']>;
  postLogoutRedirectUris: Array<Scalars['String']['output']>;
  redirectUris: Array<Scalars['String']['output']>;
};

export type JavaScriptClientCreateInput = {
  clientName: Scalars['String']['input'];
  postLogoutRedirectUris: Array<Scalars['String']['input']>;
  redirectUris: Array<Scalars['String']['input']>;
};

export type JavaScriptClientCreateOutput = {
  __typename?: 'JavaScriptClientCreateOutput';
  clientId: Scalars['String']['output'];
};

export type JavaScriptClientEditInput = {
  clientId: Scalars['String']['input'];
  clientName?: InputMaybe<Scalars['String']['input']>;
  postLogoutRedirectUris?: InputMaybe<Array<Scalars['String']['input']>>;
  redirectUris?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type JavaScriptClientFilter = {
  and?: InputMaybe<Array<JavaScriptClientFilter>>;
  clientId?: InputMaybe<StringIdFilterInput>;
  clientName?: InputMaybe<NameFilterInput>;
  or?: InputMaybe<Array<JavaScriptClientFilter>>;
};

export type LocalString = {
  __typename?: 'LocalString';
  culture: Scalars['String']['output'];
  key: Scalars['String']['output'];
  stringValue: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type LocalStringConnection = {
  __typename?: 'LocalStringConnection';
  /** A list of edges. */
  edges?: Maybe<Array<LocalStringEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<LocalString>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type LocalStringEdge = {
  __typename?: 'LocalStringEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: LocalString;
};

export type LocalStringFilter = {
  AND?: InputMaybe<Array<LocalStringFilter>>;
  OR?: InputMaybe<Array<LocalStringFilter>>;
  culture?: InputMaybe<Scalars['String']['input']>;
  culture_in?: InputMaybe<Array<Scalars['String']['input']>>;
  culture_starts_with?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  key_in?: InputMaybe<Array<Scalars['String']['input']>>;
  key_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export type LongFilterInput = {
  and?: InputMaybe<Array<LongFilterInput>>;
  eq?: InputMaybe<Scalars['Long']['input']>;
  or?: InputMaybe<Array<LongFilterInput>>;
};

export type MfaSettings = {
  __typename?: 'MfaSettings';
  mfaEnabled: Scalars['Boolean']['output'];
};

export type MfaSettingsEditInput = {
  enableMfa?: InputMaybe<Scalars['Boolean']['input']>;
};

/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type Mutation = {
  __typename?: 'Mutation';
  /** (Required Permissions: hxp.subscription.decommission) */
  accountAppDecommissioningRedriveRequest: PayloadOfSubscriptionDetail;
  accountAppDecommissioningRedriveRequestManual: PayloadOfSubscriptionDetail;
  /** (Required Permissions: hxp.subscription.activate) */
  accountAppProvisioningRedriveRequest?: Maybe<PayloadOfAccountApp>;
  accountAppProvisioningRedriveRequestManual?: Maybe<PayloadOfAccountApp>;
  /** Delete an account client (Required Permissions: iam.account-client.delete) */
  accountClientDelete?: Maybe<PayloadOfBoolean>;
  /** Reset an account client secret (Required Permissions: iam.account-client-secret.reset) */
  accountClientSecretReset?: Maybe<PayloadOfAccountClientSecretResetOutput>;
  /** Mutation to manually update an account key. */
  accountKeyUpdateManual?: Maybe<PayloadOfAccount>;
  /** Mutation to delete an App. */
  appDelete?: Maybe<PayloadOfApp>;
  /** (Required Permissions: hxp.environment.create) */
  environmentCreate: PayloadOfEnvironment;
  /** Mutation to manually update an environmentKey. */
  environmentKeyUpdateManual?: Maybe<PayloadOfEnvironment>;
  /** Create a federation email domain rule. (Required Permissions: iam.federation-email-domain-rule.create) */
  federationEmailDomainRuleCreate?: Maybe<PayloadOfHrdEmailDomainRule>;
  /** Delete a federation email domain rule (Required Permissions: iam.federation-email-domain-rule.delete) */
  federationEmailDomainRuleDelete?: Maybe<PayloadOfBoolean>;
  /** Edit an existing federation email domain rule (Required Permissions: iam.federation-email-domain-rule.edit) */
  federationEmailDomainRuleEdit?: Maybe<PayloadOfHrdEmailDomainRule>;
  /** Create a federation provider. (Required Permissions: iam.federation-provider.create) */
  federationProviderCreate?: Maybe<PayloadOfFederationProvider>;
  /** Delete an existing federation provider. (Required Permissions: iam.federation-provider.delete) */
  federationProviderDelete?: Maybe<PayloadOfBoolean>;
  /** Assign or remove email domain rules to/from a federation provider (Required Permissions: iam.federation-provider.edit) */
  federationProviderEmailDomainRulesUpdate?: Maybe<PayloadOfBoolean>;
  /** Edit an existing federation provider. (Required Permissions: iam.federation-provider.edit) */
  federationProviderPatch?: Maybe<PayloadOfFederationProvider>;
  /** Create a JavaScript client. (Required Permissions: iam.account-client.create) */
  javaScriptClientCreate?: Maybe<PayloadOfJavaScriptClientCreateOutput>;
  /** Edit an existing JavaScript client. (Required Permissions: iam.account-client.edit) */
  javaScriptClientEdit?: Maybe<PayloadOfJavaScriptClient>;
  /** Mutation to delete a localized string value from the HxP App Domain. */
  localStringDelete?: Maybe<PayloadOfLocalString>;
  /** Edit MFA settings for current Account (Required Permissions: iam.mfa.edit) */
  mfaSettingsEdit?: Maybe<PayloadOfMfaSettings>;
  /** Mutation for accepting the terms for an Account. */
  onboardingAccountAcceptTerms?: Maybe<PayloadOfTerms>;
  /** Mutation for resending admin email for the Contract specified by contractId (Required Permissions: hxp.contract.resend-email) */
  onboardingAccountRepublishAdminEvent?: Maybe<PayloadOfOnboardingContract>;
  /** Mutation for resending term email for the Contract specified by contractId (Required Permissions: hxp.contract.resend-email) */
  onboardingAccountResendTermEmail?: Maybe<PayloadOfOnboardingContract>;
  /** Mutation for resending term email from expired term email */
  onboardingAccountResendTermEmailFromExpiredTermEmail?: Maybe<PayloadOfResendExpiredTermEmailResponse>;
  /** Mutation to update term email for Contract specified by contractId (Required Permissions: hxp.contract.edit-email) */
  onboardingAccountUpdateTermEmail?: Maybe<PayloadOfOnboardingContract>;
  /** Mutation for sending Onboarding Admin Event for the Contract specified by contractId */
  onboardingContractSendAdminEmail?: Maybe<PayloadOfSendAdminEmailResponse>;
  /** Mutation to update admin email for Contract specified by contractId (Required Permissions: hxp.contract.edit-email) */
  onboardingContractUpdateAdminEmail?: Maybe<PayloadOfOnboardingContract>;
  /** Assign/Remove user groups from a role in account context (Required Permissions: iam.roleusergroup.write) */
  roleAssignUserGroupsForAccount?: Maybe<PayloadOfBoolean>;
  /** Assign/Remove user groups from a role in environment context (Required Permissions: iam.roleusergroup.write) */
  roleAssignUserGroupsForEnvironment?: Maybe<PayloadOfBoolean>;
  /** Assign/Remove users from a role in account context (Required Permissions: iam.roleuser.write) */
  roleAssignUsersForAccount?: Maybe<PayloadOfBoolean>;
  /** Assign/Remove users from a role in environment context (Required Permissions: iam.roleuser.write) */
  roleAssignUsersForEnvironment?: Maybe<PayloadOfBoolean>;
  /** Add SAML Configuration to Federation Provider. (Required Permissions: iam.federation-provider.edit) */
  samlConfigurationCreate?: Maybe<PayloadOfSamlConfiguration>;
  /** Edit an existing SAML configuration for a federation provider (Required Permissions: iam.federation-provider.edit) */
  samlConfigurationPatch?: Maybe<PayloadOfSamlConfiguration>;
  /** Create a service client. (Required Permissions: iam.account-client.create) */
  serviceClientCreate?: Maybe<PayloadOfServiceClientCreateOutput>;
  /** Edit an existing service client. (Required Permissions: iam.account-client.edit) */
  serviceClientEdit?: Maybe<PayloadOfServiceClient>;
  /** Patch an existing service client. (Required Permissions: iam.account-client.edit) */
  serviceClientPatch?: Maybe<PayloadOfServiceClient>;
  /** Create a service user (Required Permissions: iam.user.create) */
  serviceUserCreate?: Maybe<PayloadOfApplicationUser>;
  /** Update a service user (Required Permissions: iam.user.edit) */
  serviceUserEdit?: Maybe<PayloadOfApplicationUser>;
  /** (Required Permissions: hxp.subscription.decommission) */
  subscribedAppDecommissioningRedriveRequest: PayloadOfSubscriptionDetail;
  subscribedAppDecommissioningRedriveRequestManual: PayloadOfSubscriptionDetail;
  /** (Required Permissions: hxp.subscription.activate) */
  subscribedAppProvisioningRedriveRequest: PayloadOfSubscriptionDetail;
  subscribedAppProvisioningRedriveRequestManual: PayloadOfSubscriptionDetail;
  /** (Required Permissions: hxp.subscription.activate) */
  subscriptionActivateRedriveEvent: PayloadOfSubscriptionDetail;
  subscriptionActivateRedriveEventManual: PayloadOfSubscriptionDetail;
  /** (Required Permissions: hxp.subscription.activate) */
  subscriptionActivateWithEnvironment: PayloadOfSubscriptionDetail;
  /** Mutation to manually Cancel a subscription. */
  subscriptionCancelManual: PayloadOfSubscriptionDetail;
  /** Mutation to manually send a decommissioning approved message for a subscription. */
  subscriptionDecommissioningApprovedManual: PayloadOfSubscriptionDetail;
  /** Mutation to manually Expire a subscription. */
  subscriptionExpireManual: PayloadOfSubscriptionDetail;
  /** Request for password reset link (Required Permissions: iam.user.reset-password) */
  userAdministratorResetPassword?: Maybe<PayloadOfBoolean>;
  /** Archive a user (Required Permissions: iam.user.archive) */
  userArchive?: Maybe<PayloadOfBoolean>;
  /** Create a custom user attribute. (Required Permissions: iam.user-attribute.create) */
  userAttributeCreate?: Maybe<PayloadOfBoolean>;
  /** Delete a custom user attribute. (Required Permissions: iam.user-attribute.delete) */
  userAttributeDelete?: Maybe<PayloadOfBoolean>;
  /** Edit custom user attribute (Required Permissions: iam.user-attribute.edit) */
  userAttributePatch?: Maybe<PayloadOfBoolean>;
  /** Set custom user attribute values for a given user. (Required Permissions: iam.user-attribute-value.write) */
  userAttributeValuesPatch?: Maybe<PayloadOfBoolean>;
  /** Create a user (Required Permissions: iam.user.create) */
  userCreate?: Maybe<PayloadOfApplicationUser>;
  /** Deactivate a user (Required Permissions: iam.user.deactivate) */
  userDeactivate?: Maybe<PayloadOfBoolean>;
  /** Update a user (Required Permissions: iam.user.edit) */
  userEdit?: Maybe<PayloadOfApplicationUser>;
  /** Create a user group (Required Permissions: iam.usergroup.create) */
  userGroupCreate?: Maybe<PayloadOfUserGroup>;
  /** Delete a user group (Required Permissions: iam.usergroup.delete) */
  userGroupDelete?: Maybe<PayloadOfBoolean>;
  /** Update a user group (Required Permissions: iam.usergroup.edit) */
  userGroupEdit?: Maybe<PayloadOfUserGroup>;
  /** Reactivate a user (Required Permissions: iam.user.reactivate) */
  userReactivate?: Maybe<PayloadOfBoolean>;
  /** Request to reset authenticator for a given user (Required Permissions: iam.user.reset-mfa) */
  userResetMfa?: Maybe<PayloadOfBoolean>;
  /** Request for email verification and set password link (Required Permissions: iam.user.request-confirmation) */
  userSetPasswordAndConfirmEmail?: Maybe<PayloadOfBoolean>;
  /** Unlock a user (Required Permissions: iam.user.unlock) */
  userUnlock?: Maybe<PayloadOfBoolean>;
  /** Create a web server client. (Required Permissions: iam.account-client.create) */
  webServerClientCreate?: Maybe<PayloadOfWebServerClientCreateOutput>;
  /** Edit an existing web server client. (Required Permissions: iam.account-client.edit) */
  webServerClientEdit?: Maybe<PayloadOfWebServerClient>;
  /** Patch an existing web server client. (Required Permissions: iam.account-client.edit) */
  webServerClientPatch?: Maybe<PayloadOfWebServerClient>;
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationAccountAppDecommissioningRedriveRequestArgs = {
  appKey: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationAccountAppDecommissioningRedriveRequestManualArgs = {
  appKey: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationAccountAppProvisioningRedriveRequestArgs = {
  accountId: Scalars['ID']['input'];
  appKey: Scalars['String']['input'];
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationAccountAppProvisioningRedriveRequestManualArgs = {
  accountId: Scalars['ID']['input'];
  appKey: Scalars['String']['input'];
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationAccountClientDeleteArgs = {
  input: AccountClientDeleteInput;
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationAccountClientSecretResetArgs = {
  input: AccountClientSecretResetInput;
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationAccountKeyUpdateManualArgs = {
  accountId: Scalars['ID']['input'];
  accountKey: Scalars['String']['input'];
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationAppDeleteArgs = {
  id: Scalars['ID']['input'];
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationEnvironmentCreateArgs = {
  accountId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationEnvironmentKeyUpdateManualArgs = {
  environmentId: Scalars['ID']['input'];
  environmentKey: Scalars['String']['input'];
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationFederationEmailDomainRuleCreateArgs = {
  input: FederationEmailDomainRuleCreateInput;
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationFederationEmailDomainRuleDeleteArgs = {
  input: FederationEmailDomainRuleDeleteInput;
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationFederationEmailDomainRuleEditArgs = {
  input: FederationEmailDomainRuleEditInput;
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationFederationProviderCreateArgs = {
  input: FederationProviderCreateInput;
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationFederationProviderDeleteArgs = {
  input: FederationProviderDeleteInput;
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationFederationProviderEmailDomainRulesUpdateArgs = {
  input: FederationProviderEmailDomainRulesUpdateInput;
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationFederationProviderPatchArgs = {
  input: FederationProviderPatchInput;
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationJavaScriptClientCreateArgs = {
  input: JavaScriptClientCreateInput;
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationJavaScriptClientEditArgs = {
  input: JavaScriptClientEditInput;
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationLocalStringDeleteArgs = {
  key: Scalars['String']['input'];
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationMfaSettingsEditArgs = {
  input: MfaSettingsEditInput;
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationOnboardingAccountAcceptTermsArgs = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  organizationName: Scalars['String']['input'];
  title: Scalars['String']['input'];
  url: Scalars['String']['input'];
  version: Scalars['String']['input'];
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationOnboardingAccountRepublishAdminEventArgs = {
  contractId: Scalars['String']['input'];
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationOnboardingAccountResendTermEmailArgs = {
  contractId: Scalars['String']['input'];
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationOnboardingAccountResendTermEmailFromExpiredTermEmailArgs = {
  signedUrlFromTermEmail: Scalars['String']['input'];
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationOnboardingAccountUpdateTermEmailArgs = {
  contractId: Scalars['String']['input'];
  updatedTermEmail: Scalars['String']['input'];
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationOnboardingContractSendAdminEmailArgs = {
  adminEmail: Scalars['String']['input'];
  url: Scalars['String']['input'];
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationOnboardingContractUpdateAdminEmailArgs = {
  contractId: Scalars['String']['input'];
  updatedAdminEmail: Scalars['String']['input'];
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationRoleAssignUserGroupsForAccountArgs = {
  input: RoleAssignUserGroupsForAccountInput;
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationRoleAssignUserGroupsForEnvironmentArgs = {
  input: RoleAssignUserGroupsForEnvironmentInput;
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationRoleAssignUsersForAccountArgs = {
  input: RoleAssignUsersForAccountInput;
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationRoleAssignUsersForEnvironmentArgs = {
  input: RoleAssignUsersForEnvironmentInput;
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationSamlConfigurationCreateArgs = {
  input: SamlConfigurationCreateInput;
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationSamlConfigurationPatchArgs = {
  input: SamlConfigurationPatchInput;
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationServiceClientCreateArgs = {
  input: ServiceClientCreateInput;
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationServiceClientEditArgs = {
  input: ServiceClientEditInput;
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationServiceClientPatchArgs = {
  input: ServiceClientPatchInput;
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationServiceUserCreateArgs = {
  input: ServiceUserCreateInput;
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationServiceUserEditArgs = {
  input: ServiceUserEditInput;
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationSubscribedAppDecommissioningRedriveRequestArgs = {
  appKey: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationSubscribedAppDecommissioningRedriveRequestManualArgs = {
  appKey: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationSubscribedAppProvisioningRedriveRequestArgs = {
  appKey: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationSubscribedAppProvisioningRedriveRequestManualArgs = {
  appKey: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationSubscriptionActivateRedriveEventArgs = {
  id: Scalars['ID']['input'];
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationSubscriptionActivateRedriveEventManualArgs = {
  id: Scalars['ID']['input'];
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationSubscriptionActivateWithEnvironmentArgs = {
  environmentId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationSubscriptionCancelManualArgs = {
  id: Scalars['ID']['input'];
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationSubscriptionDecommissioningApprovedManualArgs = {
  nucleusRegion: Scalars['String']['input'];
  sourceAccountId: Scalars['ID']['input'];
  sourceAccountSystem: Scalars['String']['input'];
  sourceContractSystem: Scalars['String']['input'];
  sourceSubscriptionId: Scalars['ID']['input'];
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationSubscriptionExpireManualArgs = {
  id: Scalars['ID']['input'];
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationUserAdministratorResetPasswordArgs = {
  input: UserAdministratorResetPasswordInput;
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationUserArchiveArgs = {
  input: UserArchiveInput;
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationUserAttributeCreateArgs = {
  input: UserAttributeCreateInput;
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationUserAttributeDeleteArgs = {
  input: UserAttributeDeleteInput;
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationUserAttributePatchArgs = {
  input: UserAttributePatchInput;
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationUserAttributeValuesPatchArgs = {
  input: UserAttributeValuesPatchInput;
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationUserCreateArgs = {
  input: UserCreateInput;
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationUserDeactivateArgs = {
  input: UserDeactivateInput;
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationUserEditArgs = {
  input: UserEditInput;
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationUserGroupCreateArgs = {
  input: UserGroupCreateInput;
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationUserGroupDeleteArgs = {
  input: UserGroupDeleteInput;
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationUserGroupEditArgs = {
  input: UserGroupEditInput;
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationUserReactivateArgs = {
  input: UserReactivateInput;
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationUserResetMfaArgs = {
  input: UserResetMfaInput;
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationUserSetPasswordAndConfirmEmailArgs = {
  input: UserSetPasswordAndConfirmEmailInput;
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationUserUnlockArgs = {
  input: UserUnlockInput;
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationWebServerClientCreateArgs = {
  input: WebServerClientCreateInput;
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationWebServerClientEditArgs = {
  input: WebServerClientEditInput;
};


/** A mutation is used by the client to create, update and delete the data from the server. Mutation is scoped to executing user's account. */
export type MutationWebServerClientPatchArgs = {
  input: WebServerClientPatchInput;
};

export type NameFilterInput = {
  and?: InputMaybe<Array<NameFilterInput>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<NameFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NormalizedStringFilterInput = {
  and?: InputMaybe<Array<NormalizedStringFilterInput>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  or?: InputMaybe<Array<NormalizedStringFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean']['output'];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type PaginatedUsersConnection = {
  __typename?: 'PaginatedUsersConnection';
  /** A list of edges. */
  edges?: Maybe<Array<PaginatedUsersEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<User>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PaginatedUsersEdge = {
  __typename?: 'PaginatedUsersEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<User>;
};

export type PayloadOfAccount = {
  __typename?: 'PayloadOfAccount';
  userErrors?: Maybe<Array<Maybe<SubscriptionsClientError>>>;
  value?: Maybe<Account>;
};

export type PayloadOfAccountApp = {
  __typename?: 'PayloadOfAccountApp';
  userErrors?: Maybe<Array<Maybe<SubscriptionsClientError>>>;
  value?: Maybe<AccountApp>;
};

export type PayloadOfAccountClientSecretResetOutput = {
  __typename?: 'PayloadOfAccountClientSecretResetOutput';
  userErrors?: Maybe<Array<Maybe<ClientError>>>;
  value?: Maybe<AccountClientSecretResetOutput>;
};

export type PayloadOfApp = {
  __typename?: 'PayloadOfApp';
  userErrors?: Maybe<Array<Maybe<AppsClientError>>>;
  value?: Maybe<App>;
};

export type PayloadOfApplicationUser = {
  __typename?: 'PayloadOfApplicationUser';
  userErrors?: Maybe<Array<Maybe<ClientError>>>;
  value?: Maybe<User>;
};

export type PayloadOfBoolean = {
  __typename?: 'PayloadOfBoolean';
  userErrors?: Maybe<Array<Maybe<ClientError>>>;
  value?: Maybe<Scalars['Boolean']['output']>;
};

export type PayloadOfContract = {
  __typename?: 'PayloadOfContract';
  userErrors?: Maybe<Array<Maybe<SubscriptionsClientError>>>;
  value?: Maybe<Contract>;
};

export type PayloadOfEnvironment = {
  __typename?: 'PayloadOfEnvironment';
  userErrors?: Maybe<Array<Maybe<SubscriptionsClientError>>>;
  value?: Maybe<Environment>;
};

export type PayloadOfFederationProvider = {
  __typename?: 'PayloadOfFederationProvider';
  userErrors?: Maybe<Array<Maybe<ClientError>>>;
  value?: Maybe<FederationProvider>;
};

export type PayloadOfHrdEmailDomainRule = {
  __typename?: 'PayloadOfHrdEmailDomainRule';
  userErrors?: Maybe<Array<Maybe<ClientError>>>;
  value?: Maybe<FederationEmailDomainRule>;
};

export type PayloadOfJavaScriptClient = {
  __typename?: 'PayloadOfJavaScriptClient';
  userErrors?: Maybe<Array<Maybe<ClientError>>>;
  value?: Maybe<JavaScriptClient>;
};

export type PayloadOfJavaScriptClientCreateOutput = {
  __typename?: 'PayloadOfJavaScriptClientCreateOutput';
  userErrors?: Maybe<Array<Maybe<ClientError>>>;
  value?: Maybe<JavaScriptClientCreateOutput>;
};

export type PayloadOfLocalString = {
  __typename?: 'PayloadOfLocalString';
  userErrors?: Maybe<Array<Maybe<AppsClientError>>>;
  value?: Maybe<LocalString>;
};

export type PayloadOfMfaSettings = {
  __typename?: 'PayloadOfMfaSettings';
  userErrors?: Maybe<Array<Maybe<ClientError>>>;
  value?: Maybe<MfaSettings>;
};

export type PayloadOfOnboardingContract = {
  __typename?: 'PayloadOfOnboardingContract';
  userErrors?: Maybe<Array<Maybe<OnboardingClientError>>>;
  value?: Maybe<OnboardingContract>;
};

export type PayloadOfResendExpiredTermEmailResponse = {
  __typename?: 'PayloadOfResendExpiredTermEmailResponse';
  userErrors?: Maybe<Array<Maybe<OnboardingClientError>>>;
  value?: Maybe<ResendExpiredTermEmailResponse>;
};

export type PayloadOfSamlConfiguration = {
  __typename?: 'PayloadOfSamlConfiguration';
  userErrors?: Maybe<Array<Maybe<ClientError>>>;
  value?: Maybe<SamlConfiguration>;
};

export type PayloadOfSendAdminEmailResponse = {
  __typename?: 'PayloadOfSendAdminEmailResponse';
  userErrors?: Maybe<Array<Maybe<OnboardingClientError>>>;
  value?: Maybe<SendAdminEmailResponse>;
};

export type PayloadOfServiceClient = {
  __typename?: 'PayloadOfServiceClient';
  userErrors?: Maybe<Array<Maybe<ClientError>>>;
  value?: Maybe<ServiceClient>;
};

export type PayloadOfServiceClientCreateOutput = {
  __typename?: 'PayloadOfServiceClientCreateOutput';
  userErrors?: Maybe<Array<Maybe<ClientError>>>;
  value?: Maybe<ServiceClientCreateOutput>;
};

export type PayloadOfSubscriptionDetail = {
  __typename?: 'PayloadOfSubscriptionDetail';
  userErrors?: Maybe<Array<Maybe<SubscriptionsClientError>>>;
  value?: Maybe<SubscriptionDetail>;
};

export type PayloadOfTerms = {
  __typename?: 'PayloadOfTerms';
  userErrors?: Maybe<Array<Maybe<OnboardingClientError>>>;
  value?: Maybe<Terms>;
};

export type PayloadOfUsageType = {
  __typename?: 'PayloadOfUsageType';
  userErrors?: Maybe<Array<Maybe<AppsClientError>>>;
  value?: Maybe<UsageType>;
};

export type PayloadOfUserGroup = {
  __typename?: 'PayloadOfUserGroup';
  userErrors?: Maybe<Array<Maybe<ClientError>>>;
  value?: Maybe<UserGroup>;
};

export type PayloadOfWebServerClient = {
  __typename?: 'PayloadOfWebServerClient';
  userErrors?: Maybe<Array<Maybe<ClientError>>>;
  value?: Maybe<WebServerClient>;
};

export type PayloadOfWebServerClientCreateOutput = {
  __typename?: 'PayloadOfWebServerClientCreateOutput';
  userErrors?: Maybe<Array<Maybe<ClientError>>>;
  value?: Maybe<WebServerClientCreateOutput>;
};

export enum PricingMethod {
  Flatrate = 'FLATRATE',
  Perunit = 'PERUNIT'
}

export enum PricingStrategy {
  Allotment = 'ALLOTMENT',
  Perunit = 'PERUNIT',
  Stairstep = 'STAIRSTEP',
  Tiered = 'TIERED',
  Volume = 'VOLUME'
}

export enum ProviderType {
  /** SAML Federation */
  Saml = 'SAML'
}

export enum ProvisioningStatus {
  Decommissioned = 'DECOMMISSIONED',
  Pending = 'PENDING',
  Provisioned = 'PROVISIONED',
  Provisioning = 'PROVISIONING'
}

/** A query is used by the client to request the data it needs from the server. Query is scoped to executing user's account. */
export type Query = {
  __typename?: 'Query';
  /** Get onboardingContract information combined with its associated onboardingAccount information.  (The DefaultPageSize = 50, the MaxPageSize = 50) */
  ContractAndAccountView?: Maybe<ContractAndAccountViewConnection>;
  /** (Required Permissions: hxp.account.read) */
  account?: Maybe<Account>;
  /** List of available scopes for account clients */
  accountClientScopes: Array<AccountClientScope>;
  /** Query used to retrieve a specific App in the HXP App Domain. */
  app?: Maybe<App>;
  /** Query used to retrieve a specific App by Key in the HXP App Domain. */
  appByKey?: Maybe<App>;
  /** Query used to retrieve apps by id or key. If no filter is given, all apps are returned. */
  apps?: Maybe<AppConnection>;
  auditApi: Scalars['String']['output'];
  /** Current logged in user */
  currentUser: CurrentUser;
  /** Query used to retrieve localized string, If no filter is given, all localized strings are returned. */
  localStrings?: Maybe<LocalStringConnection>;
  /** Query to get list of Accounts */
  onboardingAccounts?: Maybe<Array<Maybe<OnboardingAccount>>>;
  /** Query to get list of Contracts */
  onboardingContracts?: Maybe<Array<Maybe<OnboardingContract>>>;
  /** Verify a signed URL */
  onboardingSignedUrlStatus?: Maybe<SignedUrl>;
  /** Get a subscription by id. */
  subscriptionById?: Maybe<SubscriptionDetail>;
  /** Query used to retrieve usage events, If no filter is given, all events are returned. */
  usageEvents?: Maybe<UsageEventConnection>;
  /** Query used to retrieve usage for a Subscription based on the current date */
  usageForCurrentCycle: Array<BillableUsage>;
  /** Query used to retrieve Usage Event totals related to a Subscription. */
  usageTotals?: Maybe<Array<Maybe<UsageTotal>>>;
  /** Query used to retrieve a specific Usage Type by Key in the HXP App Domain. */
  usageTypeByKey?: Maybe<UsageType>;
};


/** A query is used by the client to request the data it needs from the server. Query is scoped to executing user's account. */
export type QueryContractAndAccountViewArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<ContractAndAccountSortInput>>;
  where?: InputMaybe<ContractAndAccountFilterInput>;
};


/** A query is used by the client to request the data it needs from the server. Query is scoped to executing user's account. */
export type QueryAccountArgs = {
  id: Scalars['ID']['input'];
};


/** A query is used by the client to request the data it needs from the server. Query is scoped to executing user's account. */
export type QueryAppArgs = {
  id: Scalars['ID']['input'];
};


/** A query is used by the client to request the data it needs from the server. Query is scoped to executing user's account. */
export type QueryAppByKeyArgs = {
  key: Scalars['String']['input'];
};


/** A query is used by the client to request the data it needs from the server. Query is scoped to executing user's account. */
export type QueryAppsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AppFilter>;
};


/** A query is used by the client to request the data it needs from the server. Query is scoped to executing user's account. */
export type QueryLocalStringsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LocalStringFilter>;
};


/** A query is used by the client to request the data it needs from the server. Query is scoped to executing user's account. */
export type QueryOnboardingAccountsArgs = {
  where?: InputMaybe<AccountFilter>;
};


/** A query is used by the client to request the data it needs from the server. Query is scoped to executing user's account. */
export type QueryOnboardingContractsArgs = {
  where?: InputMaybe<ContractFilter>;
};


/** A query is used by the client to request the data it needs from the server. Query is scoped to executing user's account. */
export type QueryOnboardingSignedUrlStatusArgs = {
  url: Scalars['String']['input'];
};


/** A query is used by the client to request the data it needs from the server. Query is scoped to executing user's account. */
export type QuerySubscriptionByIdArgs = {
  id: Scalars['ID']['input'];
};


/** A query is used by the client to request the data it needs from the server. Query is scoped to executing user's account. */
export type QueryUsageEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UsageEventFilter>;
};


/** A query is used by the client to request the data it needs from the server. Query is scoped to executing user's account. */
export type QueryUsageForCurrentCycleArgs = {
  subscriptionId: Scalars['ID']['input'];
};


/** A query is used by the client to request the data it needs from the server. Query is scoped to executing user's account. */
export type QueryUsageTotalsArgs = {
  endDate: Scalars['Instant']['input'];
  startDate: Scalars['Instant']['input'];
  subscriptionId: Scalars['ID']['input'];
  usageTypeKeys: Array<InputMaybe<Scalars['String']['input']>>;
};


/** A query is used by the client to request the data it needs from the server. Query is scoped to executing user's account. */
export type QueryUsageTypeByKeyArgs = {
  key: Scalars['String']['input'];
};

export enum ReportingType {
  Measured = 'MEASURED',
  Metered = 'METERED'
}

/** Onboarding resend expired terms email response. */
export type ResendExpiredTermEmailResponse = {
  __typename?: 'ResendExpiredTermEmailResponse';
  isSuccessful: Scalars['Boolean']['output'];
};

/** A role is a set of permissions which authorize a user to perform a meaningful set of actions. */
export type Role = {
  __typename?: 'Role';
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type RoleAssignUserGroupsForAccountInput = {
  addUserGroupIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  appKey: Scalars['String']['input'];
  removeUserGroupIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  roleKey?: InputMaybe<Scalars['String']['input']>;
};

export type RoleAssignUserGroupsForEnvironmentInput = {
  addUserGroupIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  appKey: Scalars['String']['input'];
  environmentId: Scalars['ID']['input'];
  removeUserGroupIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  roleKey: Scalars['String']['input'];
};

export type RoleAssignUsersForAccountInput = {
  addUserIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  appKey: Scalars['String']['input'];
  removeUserIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  roleKey?: InputMaybe<Scalars['String']['input']>;
};

export type RoleAssignUsersForEnvironmentInput = {
  addUserIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  appKey: Scalars['String']['input'];
  environmentId: Scalars['ID']['input'];
  removeUserIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  roleKey: Scalars['String']['input'];
};

export type SamlConfiguration = {
  __typename?: 'SamlConfiguration';
  assertionDecryptionCertificate?: Maybe<Certificate>;
  authenticationRequestBinding?: Maybe<Scalars['String']['output']>;
  authenticationRequestSigningAlgorithm?: Maybe<Scalars['String']['output']>;
  authenticationRequestSigningCertificate?: Maybe<Certificate>;
  clockSkewAllowance?: Maybe<Scalars['String']['output']>;
  issuerEntityId: Scalars['String']['output'];
  metadataLocationOverride: Scalars['String']['output'];
  minimumAssertionSignatureAlgorithm?: Maybe<Scalars['String']['output']>;
  providerId: Scalars['ID']['output'];
  requestedAssertionBinding?: Maybe<Scalars['String']['output']>;
};

export type SamlConfigurationCreateInput = {
  assertionDecryptionCertificate?: InputMaybe<Scalars['String']['input']>;
  authenticationRequestBinding?: InputMaybe<Scalars['String']['input']>;
  authenticationRequestSigningAlgorithm?: InputMaybe<Scalars['String']['input']>;
  authenticationRequestSigningCertificate?: InputMaybe<Scalars['String']['input']>;
  clockSkewAllowance?: InputMaybe<Scalars['String']['input']>;
  issuerEntityId: Scalars['String']['input'];
  metadataLocationOverride: Scalars['String']['input'];
  minimumAssertionSignatureAlgorithm?: InputMaybe<Scalars['String']['input']>;
  providerId: Scalars['ID']['input'];
  requestedAssertionBinding?: InputMaybe<Scalars['String']['input']>;
};

export type SamlConfigurationPatchInput = {
  assertionDecryptionCertificate?: InputMaybe<Scalars['String']['input']>;
  authenticationRequestBinding?: InputMaybe<Scalars['String']['input']>;
  authenticationRequestSigningAlgorithm?: InputMaybe<Scalars['String']['input']>;
  authenticationRequestSigningCertificate?: InputMaybe<Scalars['String']['input']>;
  clockSkewAllowance?: InputMaybe<Scalars['String']['input']>;
  issuerEntityId?: InputMaybe<Scalars['String']['input']>;
  metadataLocationOverride?: InputMaybe<Scalars['String']['input']>;
  minimumAssertionSignatureAlgorithm?: InputMaybe<Scalars['String']['input']>;
  providerId: Scalars['ID']['input'];
  requestedAssertionBinding?: InputMaybe<Scalars['String']['input']>;
};

/** Onboarding send admin email response. */
export type SendAdminEmailResponse = {
  __typename?: 'SendAdminEmailResponse';
  id: Scalars['ID']['output'];
};

export type ServiceClient = {
  __typename?: 'ServiceClient';
  allowedScopes: Array<Scalars['String']['output']>;
  appKey?: Maybe<Scalars['String']['output']>;
  clientId: Scalars['String']['output'];
  clientName?: Maybe<Scalars['String']['output']>;
  environmentId?: Maybe<Scalars['ID']['output']>;
  secret: ClientSecretMetadata;
  /** (Required Permissions: iam.user.list) */
  user: User;
};

export type ServiceClientCreateInput = {
  allowedScopes?: InputMaybe<Array<Scalars['String']['input']>>;
  appKey?: InputMaybe<Scalars['String']['input']>;
  clientName: Scalars['String']['input'];
  environmentId?: InputMaybe<Scalars['ID']['input']>;
  userGroupIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  userId?: InputMaybe<Scalars['ID']['input']>;
  userName?: InputMaybe<Scalars['String']['input']>;
};

export type ServiceClientCreateOutput = {
  __typename?: 'ServiceClientCreateOutput';
  clientId: Scalars['String']['output'];
  secretValue: Scalars['String']['output'];
  /** (Required Permissions: iam.user.list) */
  user: User;
};

export type ServiceClientEditInput = {
  clientId: Scalars['String']['input'];
  clientName?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type ServiceClientFilter = {
  and?: InputMaybe<Array<ServiceClientFilter>>;
  clientId?: InputMaybe<StringIdFilterInput>;
  clientName?: InputMaybe<NameFilterInput>;
  or?: InputMaybe<Array<ServiceClientFilter>>;
  userId?: InputMaybe<UuidFilterInput>;
};

export type ServiceClientPatchInput = {
  addAllowedScopes?: InputMaybe<Array<Scalars['String']['input']>>;
  appKey?: InputMaybe<Scalars['String']['input']>;
  clientId: Scalars['String']['input'];
  clientName?: InputMaybe<Scalars['String']['input']>;
  environmentId?: InputMaybe<Scalars['ID']['input']>;
  removeAllowedScopes?: InputMaybe<Array<Scalars['String']['input']>>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type ServiceClientSortInput = {
  clientId?: InputMaybe<SortEnumType>;
  clientName?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type ServiceClientsPaginatedConnection = {
  __typename?: 'ServiceClientsPaginatedConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ServiceClientsPaginatedEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<ServiceClient>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ServiceClientsPaginatedEdge = {
  __typename?: 'ServiceClientsPaginatedEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ServiceClient>;
};

export type ServiceUserCreateInput = {
  /** User groups to add the user to. */
  addToGroups?: InputMaybe<Array<Scalars['ID']['input']>>;
  userName: Scalars['String']['input'];
};

export type ServiceUserEditInput = {
  /** User groups to add the user to. */
  addToGroups?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** User groups to remove the user from. */
  removeFromGroups?: InputMaybe<Array<Scalars['ID']['input']>>;
  userId: Scalars['ID']['input'];
  userName?: InputMaybe<Scalars['String']['input']>;
};

/** HxP Signed URL Validation Response */
export type SignedUrl = {
  __typename?: 'SignedUrl';
  status: SignedUrlStatus;
};

export enum SignedUrlStatus {
  Expired = 'EXPIRED',
  Invalid = 'INVALID',
  Signaturemissing = 'SIGNATUREMISSING',
  Signatureversionnotsupported = 'SIGNATUREVERSIONNOTSUPPORTED',
  Valid = 'VALID'
}

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum SourceSystem {
  Demosystem = 'DEMOSYSTEM',
  Hylandsalesforce = 'HYLANDSALESFORCE',
  Seedingsystem = 'SEEDINGSYSTEM',
  Testsystem = 'TESTSYSTEM'
}

export type SourceSystemFilterInput = {
  and?: InputMaybe<Array<SourceSystemFilterInput>>;
  eq?: InputMaybe<SourceSystem>;
  or?: InputMaybe<Array<SourceSystemFilterInput>>;
};

export type StringFilterInput = {
  and?: InputMaybe<Array<StringFilterInput>>;
  eq?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<StringFilterInput>>;
};

export type StringIdFilterInput = {
  and?: InputMaybe<Array<StringIdFilterInput>>;
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  or?: InputMaybe<Array<StringIdFilterInput>>;
};

export type SubscribedApp = {
  __typename?: 'SubscribedApp';
  app: App;
  appKey: Scalars['String']['output'];
  environment?: Maybe<Environment>;
  environmentId?: Maybe<Scalars['ID']['output']>;
  environmentKey?: Maybe<Scalars['String']['output']>;
  /** (Required Permissions: iam.role.list) */
  environmentRoles: Array<EnvironmentAppRole>;
  id: Scalars['ID']['output'];
  launchUrl?: Maybe<Scalars['String']['output']>;
  provisioningStatus: SubscribedAppProvisioningStatus;
  /** @deprecated Use provisioningStatus instead. */
  status: ProvisioningStatus;
  subscription: SubscriptionDetail;
};


export type SubscribedAppEnvironmentRolesArgs = {
  where?: InputMaybe<EnvironmentAppRoleFilterInput>;
};

export enum SubscribedAppProvisioningStatus {
  Carriedover = 'CARRIEDOVER',
  Decommissioned = 'DECOMMISSIONED',
  Decommissioning = 'DECOMMISSIONING',
  Pending = 'PENDING',
  Provisioned = 'PROVISIONED',
  Provisioning = 'PROVISIONING'
}

/** (Required Permissions: hxp.subscription.read) */
export type SubscriptionDetail = {
  __typename?: 'SubscriptionDetail';
  consumptionComponents: Array<ConsumptionComponent>;
  contract?: Maybe<Contract>;
  contractId: Scalars['ID']['output'];
  createdDate: Scalars['Instant']['output'];
  currentCycleBillableUsage: Array<BillableUsage>;
  decommissionedDate?: Maybe<Scalars['Instant']['output']>;
  endDate: Scalars['Instant']['output'];
  environment?: Maybe<Environment>;
  id: Scalars['ID']['output'];
  sourceSubscriptionId?: Maybe<Scalars['String']['output']>;
  startDate: Scalars['Instant']['output'];
  status: SubscriptionStatus;
  subscribedAccountApps: Array<AccountApp>;
  subscribedApps: Array<SubscribedApp>;
  terminatedDate?: Maybe<Scalars['Instant']['output']>;
  title: Scalars['String']['output'];
  type: SubscriptionType;
};

export type SubscriptionFilter = {
  AND?: InputMaybe<Array<SubscriptionFilter>>;
  OR?: InputMaybe<Array<SubscriptionFilter>>;
  hxpEnvironmentId?: InputMaybe<Scalars['Uuid']['input']>;
  hxpSubscriptionId?: InputMaybe<Scalars['Uuid']['input']>;
};

export enum SubscriptionStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Decommissioned = 'DECOMMISSIONED',
  Decommissioning = 'DECOMMISSIONING',
  Decommissioningapproved = 'DECOMMISSIONINGAPPROVED',
  Decommissioningawaitingapproval = 'DECOMMISSIONINGAWAITINGAPPROVAL',
  Draft = 'DRAFT',
  Expired = 'EXPIRED',
  Onhold = 'ONHOLD',
  Provisioning = 'PROVISIONING'
}

export enum SubscriptionType {
  Guideddemo = 'GUIDEDDEMO',
  Standard = 'STANDARD',
  Trial = 'TRIAL'
}

export type SubscriptionsClientError = {
  __typename?: 'SubscriptionsClientError';
  code?: Maybe<Scalars['String']['output']>;
  field?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type Terms = {
  __typename?: 'Terms';
  accountId?: Maybe<Scalars['ID']['output']>;
  termAcceptedDateTime?: Maybe<Scalars['Instant']['output']>;
  termEmail?: Maybe<Scalars['String']['output']>;
  termFirstName?: Maybe<Scalars['String']['output']>;
  termIp?: Maybe<Scalars['String']['output']>;
  termLastName?: Maybe<Scalars['String']['output']>;
  termOrganizationName?: Maybe<Scalars['String']['output']>;
  termTitle?: Maybe<Scalars['String']['output']>;
  termVersion?: Maybe<Scalars['String']['output']>;
};

export type Tiered = IPricingStrategy & {
  __typename?: 'Tiered';
  cycleCount: Scalars['Int']['output'];
  cyclePeriod: UsageCyclePeriod;
  cyclePeriodEndDate: Scalars['Instant']['output'];
  cyclePeriodStartDate: Scalars['Instant']['output'];
};

export enum UnitOfMeasure {
  Count = 'COUNT',
  Kilobyte = 'KILOBYTE'
}

export enum UsageCyclePeriod {
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Yearly = 'YEARLY'
}

export type UsageEvent = {
  __typename?: 'UsageEvent';
  appKey?: Maybe<Scalars['String']['output']>;
  eventDate: Scalars['Instant']['output'];
  id: Scalars['ID']['output'];
  quantity: Scalars['Int']['output'];
  usageEventMetadata?: Maybe<Array<Maybe<UsageEventMetadata>>>;
  usageType?: Maybe<UsageType>;
  usageTypeKey: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type UsageEventConnection = {
  __typename?: 'UsageEventConnection';
  /** A list of edges. */
  edges?: Maybe<Array<UsageEventEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<UsageEvent>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type UsageEventEdge = {
  __typename?: 'UsageEventEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: UsageEvent;
};

export type UsageEventFilter = {
  AND?: InputMaybe<Array<UsageEventFilter>>;
  OR?: InputMaybe<Array<UsageEventFilter>>;
  appKey?: InputMaybe<Scalars['String']['input']>;
  appKey_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  eventDate?: InputMaybe<Scalars['Instant']['input']>;
  eventDate_gt?: InputMaybe<Scalars['Instant']['input']>;
  eventDate_gte?: InputMaybe<Scalars['Instant']['input']>;
  eventDate_in?: InputMaybe<Array<Scalars['Instant']['input']>>;
  eventDate_lt?: InputMaybe<Scalars['Instant']['input']>;
  eventDate_lte?: InputMaybe<Scalars['Instant']['input']>;
  eventDate_not_gt?: InputMaybe<Scalars['Instant']['input']>;
  eventDate_not_gte?: InputMaybe<Scalars['Instant']['input']>;
  eventDate_not_lt?: InputMaybe<Scalars['Instant']['input']>;
  eventDate_not_lte?: InputMaybe<Scalars['Instant']['input']>;
  subscriptionId?: InputMaybe<Scalars['Uuid']['input']>;
  subscriptionId_in?: InputMaybe<Array<InputMaybe<Scalars['Uuid']['input']>>>;
  usageTypeKey?: InputMaybe<Scalars['String']['input']>;
  usageTypeKey_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UsageEventMetadata = {
  __typename?: 'UsageEventMetadata';
  fieldName: Scalars['String']['output'];
  fieldValue: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export enum UsagePricingStrategy {
  Allotment = 'ALLOTMENT',
  Perunit = 'PERUNIT',
  Stairstep = 'STAIRSTEP',
  Tiered = 'TIERED',
  Volume = 'VOLUME'
}

export type UsageTotal = {
  __typename?: 'UsageTotal';
  count: Scalars['Int']['output'];
  usageType: UsageType;
  usageTypeKey: Scalars['String']['output'];
};

export type UsageType = {
  __typename?: 'UsageType';
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  localizedDescription: Scalars['String']['output'];
  localizedName: Scalars['String']['output'];
  reportingType: ReportingType;
  unitOfMeasure: UnitOfMeasure;
  usageTypeMetadata?: Maybe<Array<Maybe<UsageTypeMetadata>>>;
};

export type UsageTypeMetadata = {
  __typename?: 'UsageTypeMetadata';
  fieldName: Scalars['String']['output'];
  fieldValue: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type UsageTypeMetadataInput = {
  fieldName: Scalars['String']['input'];
  fieldValue: Scalars['String']['input'];
};

export enum UsageUnitOfMeasure {
  Count = 'COUNT',
  Kilobyte = 'KILOBYTE'
}

/** The user represents the person or service entity to interact with HxP. */
export type User = {
  __typename?: 'User';
  account: Account;
  archived: Scalars['Boolean']['output'];
  /** Retrieve custom attribute assignments for a User. (Required Permissions: iam.user-attribute-value.read) */
  attributeValueAssignments?: Maybe<AttributeValueAssignmentsConnection>;
  deactivated: Scalars['Boolean']['output'];
  displayName: Scalars['String']['output'];
  email?: Maybe<Email>;
  emailVerified: Scalars['Boolean']['output'];
  /** @deprecated The `Emails` field will be removed on 2024-10-05T23:59:59Z. Use the `Email` field instead. */
  emails: Array<Maybe<Email>>;
  groups: Array<UserGroup>;
  id: Scalars['ID']['output'];
  locked: Scalars['Boolean']['output'];
  mfaEnabled: Scalars['Boolean']['output'];
  name: UserName;
  type: UserType;
  userName: Scalars['String']['output'];
};


/** The user represents the person or service entity to interact with HxP. */
export type UserAttributeValueAssignmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<UserAttributeValueAssignmentSortInput>>;
  where?: InputMaybe<UserAttributeValueAssignmentFilterInput>;
};

export type UserAdministratorResetPasswordInput = {
  userId: Scalars['ID']['input'];
};

export type UserArchiveInput = {
  userId: Scalars['ID']['input'];
};

/** (Required Permissions: iam.user-attribute.read) */
export type UserAttribute = {
  __typename?: 'UserAttribute';
  displayName?: Maybe<Scalars['String']['output']>;
  federationProviders: Array<FederationProvider>;
  key: Scalars['String']['output'];
};

export type UserAttributeCreateInput = {
  displayName?: InputMaybe<Scalars['String']['input']>;
  key: Scalars['String']['input'];
};

export type UserAttributeDeleteInput = {
  key: Scalars['String']['input'];
};

export type UserAttributeFilterInput = {
  and?: InputMaybe<Array<UserAttributeFilterInput>>;
  key?: InputMaybe<NormalizedStringFilterInput>;
  or?: InputMaybe<Array<UserAttributeFilterInput>>;
};

export type UserAttributePatchInput = {
  currentKey: Scalars['String']['input'];
  displayName?: InputMaybe<Scalars['String']['input']>;
  newKey?: InputMaybe<Scalars['String']['input']>;
};

export type UserAttributeSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  key?: InputMaybe<SortEnumType>;
};

export type UserAttributeValueAssignment = {
  __typename?: 'UserAttributeValueAssignment';
  attribute: UserAttribute;
  stringValue: Scalars['String']['output'];
  userId: Scalars['ID']['output'];
};

export type UserAttributeValueAssignmentFilterInput = {
  and?: InputMaybe<Array<UserAttributeValueAssignmentFilterInput>>;
  attribute?: InputMaybe<UserAttributeFilterInput>;
  or?: InputMaybe<Array<UserAttributeValueAssignmentFilterInput>>;
};

export type UserAttributeValueAssignmentInput = {
  attributeKey: Scalars['String']['input'];
  stringValue?: InputMaybe<Scalars['String']['input']>;
};

export type UserAttributeValueAssignmentSortInput = {
  attribute?: InputMaybe<Array<UserAttributeSortInput>>;
};

export type UserAttributeValuesPatchInput = {
  attributes: Array<UserAttributeValueAssignmentInput>;
  userId: Scalars['ID']['input'];
};

/** A connection to a list of items. */
export type UserAttributesConnection = {
  __typename?: 'UserAttributesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<UserAttributesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<UserAttribute>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type UserAttributesEdge = {
  __typename?: 'UserAttributesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<UserAttribute>;
};

export enum UserAuditType {
  System = 'SYSTEM',
  User = 'USER'
}

export type UserCreateInput = {
  /** User groups to add the user to. */
  addToGroups?: InputMaybe<Array<Scalars['ID']['input']>>;
  email?: InputMaybe<EmailInput>;
  emails?: InputMaybe<Array<InputMaybe<EmailInput>>>;
  familyName?: InputMaybe<Scalars['String']['input']>;
  givenName?: InputMaybe<Scalars['String']['input']>;
  /** The `name` field is deprecated and will be removed by 2025-04-01T23:59:59Z. */
  name?: InputMaybe<Scalars['String']['input']>;
  userName: Scalars['String']['input'];
};

export type UserDeactivateInput = {
  userId: Scalars['ID']['input'];
};

export type UserEditInput = {
  userId: Scalars['ID']['input'];
  userInput: UserInput;
};

export type UserFilterInput = {
  and?: InputMaybe<Array<UserFilterInput>>;
  displayName?: InputMaybe<NormalizedStringFilterInput>;
  email?: InputMaybe<NormalizedStringFilterInput>;
  emailConfirmed?: InputMaybe<BooleanFilterInput>;
  id?: InputMaybe<UuidFilterInput>;
  isDeactivated?: InputMaybe<BooleanFilterInput>;
  isLocked?: InputMaybe<BooleanFilterInput>;
  or?: InputMaybe<Array<UserFilterInput>>;
  userName?: InputMaybe<NormalizedStringFilterInput>;
  userType?: InputMaybe<EnumFilterInputTypeOfApplicationUserTypeFilterInput>;
};

/** A group is a collection of users. */
export type UserGroup = {
  __typename?: 'UserGroup';
  account: Account;
  displayName: Scalars['String']['output'];
  groupType: UserGroupType;
  id: Scalars['ID']['output'];
  /** (Required Permissions: iam.user.list) */
  members: Array<User>;
};


/** A group is a collection of users. */
export type UserGroupMembersArgs = {
  include?: InputMaybe<UserIncludeInput>;
};

export type UserGroupCreateInput = {
  groupType?: InputMaybe<UserGroupType>;
  name: Scalars['String']['input'];
  /** Users to add to the user group. */
  users?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type UserGroupDeleteInput = {
  userGroupId: Scalars['ID']['input'];
};

export type UserGroupEditInput = {
  userGroupId: Scalars['ID']['input'];
  userGroupInput: UserGroupInput;
};

export type UserGroupFilterInput = {
  and?: InputMaybe<Array<UserGroupFilterInput>>;
  id?: InputMaybe<UuidFilterInput>;
  name?: InputMaybe<NormalizedStringFilterInput>;
  or?: InputMaybe<Array<UserGroupFilterInput>>;
};

export type UserGroupInput = {
  /** Users to add to the user group. */
  addUserIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  groupType?: InputMaybe<UserGroupType>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** Users to remove from the user group. */
  removeUserIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Type of Group */
export enum UserGroupType {
  External = 'EXTERNAL',
  Internal = 'INTERNAL'
}

/** Controls whether certain users who are filtered out by default are included in the results. If null, users who are filtered out by default remain excluded from results. */
export type UserIncludeInput = {
  /** If true, archived users are included in the result. Archived users are excluded by default. */
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  /** If true, deactivated users are included in the result. Deactivated users are excluded by default. */
  deactivated?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserInput = {
  /** User groups to add the user to. */
  addToGroups?: InputMaybe<Array<Scalars['ID']['input']>>;
  email?: InputMaybe<EmailInput>;
  emails?: InputMaybe<Array<InputMaybe<EmailInput>>>;
  familyName?: InputMaybe<Scalars['String']['input']>;
  givenName?: InputMaybe<Scalars['String']['input']>;
  /** The `name` field is deprecated and will be removed by 2025-04-01T23:59:59Z. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** User groups to remove the user from. */
  removeFromGroups?: InputMaybe<Array<Scalars['ID']['input']>>;
  userName?: InputMaybe<Scalars['String']['input']>;
};

export type UserName = {
  __typename?: 'UserName';
  familyName: Scalars['String']['output'];
  givenName: Scalars['String']['output'];
};

export type UserReactivateInput = {
  userId: Scalars['ID']['input'];
};

export type UserResetMfaInput = {
  userId: Scalars['ID']['input'];
};

export type UserSetPasswordAndConfirmEmailInput = {
  userId: Scalars['ID']['input'];
};

export type UserSortInput = {
  familyName?: InputMaybe<SortEnumType>;
  givenName?: InputMaybe<SortEnumType>;
  userName?: InputMaybe<SortEnumType>;
};

/** Retrieve limited information regarding a user. */
export type UserSummary = {
  __typename?: 'UserSummary';
  archived: Scalars['Boolean']['output'];
  deactivated: Scalars['Boolean']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  userName: Scalars['String']['output'];
};

export type UserSummaryFilterInput = {
  and?: InputMaybe<Array<UserSummaryFilterInput>>;
  id?: InputMaybe<UuidFilterInput>;
  isDeactivated?: InputMaybe<BooleanFilterInput>;
  or?: InputMaybe<Array<UserSummaryFilterInput>>;
};

/** Type of user */
export enum UserType {
  /** Interactive User */
  Interactive = 'INTERACTIVE',
  /** Service User */
  Service = 'SERVICE'
}

export type UserUnlockInput = {
  userId: Scalars['ID']['input'];
};

export type UuidFilterInput = {
  and?: InputMaybe<Array<UuidFilterInput>>;
  eq?: InputMaybe<Scalars['Uuid']['input']>;
  in?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  or?: InputMaybe<Array<UuidFilterInput>>;
};

export type WebServerClient = {
  __typename?: 'WebServerClient';
  allowedScopes: Array<Scalars['String']['output']>;
  appKey?: Maybe<Scalars['String']['output']>;
  clientId: Scalars['String']['output'];
  clientName?: Maybe<Scalars['String']['output']>;
  environmentId?: Maybe<Scalars['ID']['output']>;
  postLogoutRedirectUris: Array<Scalars['String']['output']>;
  redirectUris: Array<Scalars['String']['output']>;
  secret: ClientSecretMetadata;
};

export type WebServerClientCreateInput = {
  allowedScopes?: InputMaybe<Array<Scalars['String']['input']>>;
  appKey?: InputMaybe<Scalars['String']['input']>;
  clientName: Scalars['String']['input'];
  environmentId?: InputMaybe<Scalars['ID']['input']>;
  postLogoutRedirectUris: Array<Scalars['String']['input']>;
  redirectUris: Array<Scalars['String']['input']>;
};

export type WebServerClientCreateOutput = {
  __typename?: 'WebServerClientCreateOutput';
  clientId: Scalars['String']['output'];
  secretValue: Scalars['String']['output'];
};

export type WebServerClientEditInput = {
  clientId: Scalars['String']['input'];
  clientName?: InputMaybe<Scalars['String']['input']>;
  postLogoutRedirectUris?: InputMaybe<Array<Scalars['String']['input']>>;
  redirectUris?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type WebServerClientFilter = {
  and?: InputMaybe<Array<WebServerClientFilter>>;
  clientId?: InputMaybe<StringIdFilterInput>;
  clientName?: InputMaybe<NameFilterInput>;
  or?: InputMaybe<Array<WebServerClientFilter>>;
};

export type WebServerClientPatchInput = {
  addAllowedScopes?: InputMaybe<Array<Scalars['String']['input']>>;
  addPostLogoutRedirectUris?: InputMaybe<Array<Scalars['String']['input']>>;
  addRedirectUris?: InputMaybe<Array<Scalars['String']['input']>>;
  appKey?: InputMaybe<Scalars['String']['input']>;
  clientId: Scalars['String']['input'];
  clientName?: InputMaybe<Scalars['String']['input']>;
  environmentId?: InputMaybe<Scalars['ID']['input']>;
  removeAllowedScopes?: InputMaybe<Array<Scalars['String']['input']>>;
  removePostLogoutRedirectUris?: InputMaybe<Array<Scalars['String']['input']>>;
  removeRedirectUris?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type WebServerClientSortInput = {
  clientId?: InputMaybe<SortEnumType>;
  clientName?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type WebServerClientsPaginatedConnection = {
  __typename?: 'WebServerClientsPaginatedConnection';
  /** A list of edges. */
  edges?: Maybe<Array<WebServerClientsPaginatedEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<WebServerClient>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type WebServerClientsPaginatedEdge = {
  __typename?: 'WebServerClientsPaginatedEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<WebServerClient>;
};

/** (Required Permissions: hxp.contract.read) */
export type OnboardingAccount = {
  __typename?: 'onboardingAccount';
  hsiNumber?: Maybe<Scalars['Long']['output']>;
  hxpAccountId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  sourceAccountId: Scalars['String']['output'];
  sourceAccountSystem: SourceSystem;
  termAcceptedDateTime?: Maybe<Scalars['Instant']['output']>;
  termEmail?: Maybe<Scalars['String']['output']>;
  termFirstName?: Maybe<Scalars['String']['output']>;
  termIp?: Maybe<Scalars['String']['output']>;
  termLastName?: Maybe<Scalars['String']['output']>;
  termOrganizationName?: Maybe<Scalars['String']['output']>;
  termTitle?: Maybe<Scalars['String']['output']>;
  termVersion?: Maybe<Scalars['String']['output']>;
};

export type OnboardingClientError = {
  __typename?: 'onboardingClientError';
  code?: Maybe<Scalars['String']['output']>;
  field?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

/** HxP Contract (Required Permissions: hxp.contract.read) */
export type OnboardingContract = {
  __typename?: 'onboardingContract';
  account: OnboardingAccount;
  adminEmail?: Maybe<Scalars['String']['output']>;
  completedDateTime?: Maybe<Scalars['Instant']['output']>;
  contactEmail: Scalars['String']['output'];
  contractDate: Scalars['Instant']['output'];
  contractLog: Array<ContractLog>;
  createdDateTime: Scalars['Instant']['output'];
  hxpContractId?: Maybe<Scalars['ID']['output']>;
  hxpContractS3BucketName: Scalars['String']['output'];
  hxpContractS3Key: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  messageId: Scalars['ID']['output'];
  onboardingAccountId: Scalars['ID']['output'];
  onboardingSubscriptions: Array<ContractSubscription>;
  onboardingSupportAccountId: Scalars['ID']['output'];
  sourceContractId: Scalars['ID']['output'];
  sourceContractSystem: SourceSystem;
  state: ContractState;
  status: ContractStatus;
  supportAccount: OnboardingAccount;
  type: ContractType;
};


/** HxP Contract (Required Permissions: hxp.contract.read) */
export type OnboardingContractOnboardingSubscriptionsArgs = {
  where?: InputMaybe<SubscriptionFilter>;
};

export type NewSubscriptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type NewSubscriptionsQuery = { __typename?: 'Query', currentUser: { __typename?: 'CurrentUser', id: string, homeAccount: { __typename?: 'CurrentUserAccount', id: string, account: { __typename?: 'Account', id: string, subscriptions: Array<{ __typename?: 'SubscriptionDetail', id: string, createdDate: any, status: SubscriptionStatus, environment?: { __typename?: 'Environment', id: string } | null }> } } } };

export type MfaSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type MfaSettingsQuery = { __typename?: 'Query', currentUser: { __typename?: 'CurrentUser', id: string, homeAccount: { __typename?: 'CurrentUserAccount', id: string, account: { __typename?: 'Account', id: string, mfaSettings: { __typename?: 'MfaSettings', mfaEnabled: boolean } } } } };

export type SetMfaSettingsMutationVariables = Exact<{
  mfaSettings: MfaSettingsEditInput;
}>;


export type SetMfaSettingsMutation = { __typename?: 'Mutation', mfaSettingsEdit?: { __typename?: 'PayloadOfMfaSettings', userErrors?: Array<{ __typename?: 'ClientError', code?: Error | null, field?: string | null, message?: string | null } | null> | null, value?: { __typename?: 'MfaSettings', mfaEnabled: boolean } | null } | null };

export type ServiceClientCreateMutationVariables = Exact<{
  input: ServiceClientCreateInput;
}>;


export type ServiceClientCreateMutation = { __typename?: 'Mutation', serviceClientCreate?: { __typename?: 'PayloadOfServiceClientCreateOutput', value?: { __typename?: 'ServiceClientCreateOutput', clientId: string, secretValue: string, user: { __typename?: 'User', userName: string, id: string } } | null, userErrors?: Array<{ __typename?: 'ClientError', code?: Error | null, field?: string | null, message?: string | null } | null> | null } | null };

export type WebServerClientCreateMutationVariables = Exact<{
  input: WebServerClientCreateInput;
}>;


export type WebServerClientCreateMutation = { __typename?: 'Mutation', webServerClientCreate?: { __typename?: 'PayloadOfWebServerClientCreateOutput', value?: { __typename?: 'WebServerClientCreateOutput', clientId: string, secretValue: string } | null, userErrors?: Array<{ __typename?: 'ClientError', code?: Error | null, field?: string | null, message?: string | null } | null> | null } | null };

export type ExternalAppsQueryVariables = Exact<{
  webServerClientFilter?: InputMaybe<WebServerClientFilter>;
  javaScriptClientFilter?: InputMaybe<JavaScriptClientFilter>;
  serviceClientFilter?: InputMaybe<ServiceClientFilter>;
}>;


export type ExternalAppsQuery = { __typename?: 'Query', currentUser: { __typename?: 'CurrentUser', id: string, homeAccount: { __typename?: 'CurrentUserAccount', id: string, account: { __typename?: 'Account', id: string, serviceClients: Array<{ __typename?: 'ServiceClient', clientId: string, clientName?: string | null, allowedScopes: Array<string>, environmentId?: string | null, appKey?: string | null, secret: { __typename?: 'ClientSecretMetadata', createdOn: Date }, user: { __typename?: 'User', userName: string, id: string } }>, webServerClients: Array<{ __typename?: 'WebServerClient', clientId: string, clientName?: string | null, postLogoutRedirectUris: Array<string>, redirectUris: Array<string>, allowedScopes: Array<string>, environmentId?: string | null, appKey?: string | null, secret: { __typename?: 'ClientSecretMetadata', createdOn: Date } }>, javaScriptClients: Array<{ __typename?: 'JavaScriptClient', clientId: string, clientName?: string | null }> } } } };

export type CheckExternalAppNameQueryVariables = Exact<{
  webServerClientFilter?: InputMaybe<WebServerClientFilter>;
  javaScriptClientFilter?: InputMaybe<JavaScriptClientFilter>;
  serviceClientFilter?: InputMaybe<ServiceClientFilter>;
}>;


export type CheckExternalAppNameQuery = { __typename?: 'Query', currentUser: { __typename?: 'CurrentUser', id: string, homeAccount: { __typename?: 'CurrentUserAccount', id: string, account: { __typename?: 'Account', id: string, serviceClients: Array<{ __typename?: 'ServiceClient', clientName?: string | null }>, webServerClients: Array<{ __typename?: 'WebServerClient', clientName?: string | null }>, javaScriptClients: Array<{ __typename?: 'JavaScriptClient', clientName?: string | null }> } } } };

export type SaveServiceClientMutationVariables = Exact<{
  serviceClientEditInput: ServiceClientEditInput;
}>;


export type SaveServiceClientMutation = { __typename?: 'Mutation', serviceClientEdit?: { __typename?: 'PayloadOfServiceClient', value?: { __typename?: 'ServiceClient', clientId: string, clientName?: string | null, user: { __typename?: 'User', id: string, userName: string } } | null, userErrors?: Array<{ __typename?: 'ClientError', code?: Error | null, field?: string | null, message?: string | null } | null> | null } | null };

export type PatchServiceClientMutationVariables = Exact<{
  input: ServiceClientPatchInput;
}>;


export type PatchServiceClientMutation = { __typename?: 'Mutation', serviceClientPatch?: { __typename?: 'PayloadOfServiceClient', value?: { __typename?: 'ServiceClient', clientId: string } | null, userErrors?: Array<{ __typename?: 'ClientError', code?: Error | null, field?: string | null, message?: string | null } | null> | null } | null };

export type AccountClientDeleteMutationVariables = Exact<{
  accountClientDeleteInput: AccountClientDeleteInput;
}>;


export type AccountClientDeleteMutation = { __typename?: 'Mutation', accountClientDelete?: { __typename?: 'PayloadOfBoolean', userErrors?: Array<{ __typename?: 'ClientError', code?: Error | null, field?: string | null, message?: string | null } | null> | null } | null };

export type AccountClientSecretResetMutationVariables = Exact<{
  accountClientSecretInput: AccountClientSecretResetInput;
}>;


export type AccountClientSecretResetMutation = { __typename?: 'Mutation', accountClientSecretReset?: { __typename?: 'PayloadOfAccountClientSecretResetOutput', value?: { __typename?: 'AccountClientSecretResetOutput', secretValue: string } | null, userErrors?: Array<{ __typename?: 'ClientError', code?: Error | null, field?: string | null, message?: string | null } | null> | null } | null };

export type AllowedScopesQueryVariables = Exact<{ [key: string]: never; }>;


export type AllowedScopesQuery = { __typename?: 'Query', accountClientScopes: Array<{ __typename?: 'AccountClientScope', scope: string }> };

export type WebServerClientPatchInputMutationVariables = Exact<{
  input: WebServerClientPatchInput;
}>;


export type WebServerClientPatchInputMutation = { __typename?: 'Mutation', webServerClientPatch?: { __typename?: 'PayloadOfWebServerClient', value?: { __typename?: 'WebServerClient', clientId: string, clientName?: string | null, postLogoutRedirectUris: Array<string>, redirectUris: Array<string> } | null, userErrors?: Array<{ __typename?: 'ClientError', code?: Error | null, field?: string | null, message?: string | null } | null> | null } | null };

export type IdentityProvidersQueryVariables = Exact<{ [key: string]: never; }>;


export type IdentityProvidersQuery = { __typename?: 'Query', currentUser: { __typename?: 'CurrentUser', id: string, homeAccount: { __typename?: 'CurrentUserAccount', id: string, account: { __typename?: 'Account', id: string, federationProviders: Array<{ __typename?: 'FederationProvider', displayName: string, providerId: string, enabled: boolean, samlConfiguration?: { __typename?: 'SamlConfiguration', issuerEntityId: string } | null, federationEmailDomainRules?: Array<{ __typename?: 'FederationEmailDomainRule', domain: string }> | null }> } } } };

export type IdentityProviderQueryVariables = Exact<{
  where?: InputMaybe<FederationProviderFilterInput>;
}>;


export type IdentityProviderQuery = { __typename?: 'Query', currentUser: { __typename?: 'CurrentUser', id: string, homeAccount: { __typename?: 'CurrentUserAccount', id: string, account: { __typename?: 'Account', id: string, federationProviders: Array<{ __typename?: 'FederationProvider', displayName: string, providerId: string, enabled: boolean, providerType: ProviderType, samlConfiguration?: { __typename?: 'SamlConfiguration', issuerEntityId: string, requestedAssertionBinding?: string | null, authenticationRequestBinding?: string | null, minimumAssertionSignatureAlgorithm?: string | null, metadataLocationOverride: string } | null, claims?: { __typename?: 'FederationProviderClaims', externalUserIdClaim: string, emailClaim: string, userNameClaim?: string | null, firstNameClaim?: string | null, lastNameClaim?: string | null, groupClaim?: string | null } | null, userAttributeAssignments: Array<{ __typename?: 'FederationProviderUserAttributeAssignment', sourceClaimType: string, userAttribute: { __typename?: 'UserAttribute', key: string, displayName?: string | null } }>, federationEmailDomainRules?: Array<{ __typename?: 'FederationEmailDomainRule', domain: string, enableLocalLogin: boolean, key: string }> | null }> } } } };

export type EmailDomainsQueryVariables = Exact<{ [key: string]: never; }>;


export type EmailDomainsQuery = { __typename?: 'Query', currentUser: { __typename?: 'CurrentUser', id: string, homeAccount: { __typename?: 'CurrentUserAccount', id: string, account: { __typename?: 'Account', id: string, federationEmailDomainRules: Array<{ __typename?: 'FederationEmailDomainRule', domain: string, enableLocalLogin: boolean, key: string }> } } } };

export type DeleteEmailDomainMutationVariables = Exact<{
  input: FederationEmailDomainRuleDeleteInput;
}>;


export type DeleteEmailDomainMutation = { __typename?: 'Mutation', federationEmailDomainRuleDelete?: { __typename?: 'PayloadOfBoolean', userErrors?: Array<{ __typename?: 'ClientError', code?: Error | null, message?: string | null, field?: string | null } | null> | null } | null };

export type PatchIdentityProviderMutationVariables = Exact<{
  input: FederationProviderPatchInput;
}>;


export type PatchIdentityProviderMutation = { __typename?: 'Mutation', federationProviderPatch?: { __typename?: 'PayloadOfFederationProvider', userErrors?: Array<{ __typename?: 'ClientError', code?: Error | null, message?: string | null, field?: string | null } | null> | null } | null };

export type PatchSamlConfigurationMutationVariables = Exact<{
  input: SamlConfigurationPatchInput;
}>;


export type PatchSamlConfigurationMutation = { __typename?: 'Mutation', samlConfigurationPatch?: { __typename?: 'PayloadOfSamlConfiguration', userErrors?: Array<{ __typename?: 'ClientError', code?: Error | null, message?: string | null, field?: string | null } | null> | null } | null };

export type FederationProviderCreateMutationVariables = Exact<{
  input: FederationProviderCreateInput;
}>;


export type FederationProviderCreateMutation = { __typename?: 'Mutation', federationProviderCreate?: { __typename?: 'PayloadOfFederationProvider', value?: { __typename?: 'FederationProvider', providerId: string } | null, userErrors?: Array<{ __typename?: 'ClientError', code?: Error | null, message?: string | null, field?: string | null } | null> | null } | null };

export type SamlConfigurationCreateMutationVariables = Exact<{
  input: SamlConfigurationCreateInput;
}>;


export type SamlConfigurationCreateMutation = { __typename?: 'Mutation', samlConfigurationCreate?: { __typename?: 'PayloadOfSamlConfiguration', value?: { __typename?: 'SamlConfiguration', providerId: string } | null, userErrors?: Array<{ __typename?: 'ClientError', code?: Error | null, message?: string | null, field?: string | null } | null> | null } | null };

export type DeleteIdentityProviderMutationVariables = Exact<{
  input: FederationProviderDeleteInput;
}>;


export type DeleteIdentityProviderMutation = { __typename?: 'Mutation', federationProviderDelete?: { __typename?: 'PayloadOfBoolean', value?: boolean | null, userErrors?: Array<{ __typename?: 'ClientError', code?: Error | null, message?: string | null, field?: string | null } | null> | null } | null };

export type FederationEmailDomainRuleCreateMutationVariables = Exact<{
  input: FederationEmailDomainRuleCreateInput;
}>;


export type FederationEmailDomainRuleCreateMutation = { __typename?: 'Mutation', federationEmailDomainRuleCreate?: { __typename?: 'PayloadOfHrdEmailDomainRule', value?: { __typename?: 'FederationEmailDomainRule', key: string } | null, userErrors?: Array<{ __typename?: 'ClientError', code?: Error | null, message?: string | null, field?: string | null } | null> | null } | null };

export type FederationProviderEmailDomainRulesUpdateMutationVariables = Exact<{
  input: FederationProviderEmailDomainRulesUpdateInput;
}>;


export type FederationProviderEmailDomainRulesUpdateMutation = { __typename?: 'Mutation', federationProviderEmailDomainRulesUpdate?: { __typename?: 'PayloadOfBoolean', value?: boolean | null, userErrors?: Array<{ __typename?: 'ClientError', code?: Error | null, field?: string | null, message?: string | null } | null> | null } | null };

export type FederationEmailDomainRuleEditMutationVariables = Exact<{
  input: FederationEmailDomainRuleEditInput;
}>;


export type FederationEmailDomainRuleEditMutation = { __typename?: 'Mutation', federationEmailDomainRuleEdit?: { __typename?: 'PayloadOfHrdEmailDomainRule', value?: { __typename?: 'FederationEmailDomainRule', key: string } | null, userErrors?: Array<{ __typename?: 'ClientError', code?: Error | null, message?: string | null, field?: string | null } | null> | null } | null };

export type UserGroupsQueryVariables = Exact<{
  where?: InputMaybe<UserGroupFilterInput>;
}>;


export type UserGroupsQuery = { __typename?: 'Query', currentUser: { __typename?: 'CurrentUser', id: string, homeAccount: { __typename?: 'CurrentUserAccount', id: string, account: { __typename?: 'Account', id: string, groups: Array<{ __typename?: 'UserGroup', id: string, displayName: string }> } } } };

export type UserGroupInformationQueryVariables = Exact<{
  where: UserGroupFilterInput;
}>;


export type UserGroupInformationQuery = { __typename?: 'Query', currentUser: { __typename?: 'CurrentUser', id: string, homeAccount: { __typename?: 'CurrentUserAccount', id: string, account: { __typename?: 'Account', id: string, groups: Array<{ __typename?: 'UserGroup', id: string, displayName: string, groupType: UserGroupType, members: Array<{ __typename?: 'User', id: string, displayName: string, userName: string, name: { __typename?: 'UserName', givenName: string, familyName: string } }> }> } } } };

export type CreateUserGroupMutationVariables = Exact<{
  input: UserGroupCreateInput;
}>;


export type CreateUserGroupMutation = { __typename?: 'Mutation', userGroupCreate?: { __typename?: 'PayloadOfUserGroup', value?: { __typename?: 'UserGroup', id: string, displayName: string } | null, userErrors?: Array<{ __typename?: 'ClientError', code?: Error | null, field?: string | null, message?: string | null } | null> | null } | null };

export type EditUserGroupMutationVariables = Exact<{
  input: UserGroupEditInput;
}>;


export type EditUserGroupMutation = { __typename?: 'Mutation', userGroupEdit?: { __typename?: 'PayloadOfUserGroup', value?: { __typename?: 'UserGroup', id: string, displayName: string, members: Array<{ __typename?: 'User', id: string, displayName: string, userName: string, name: { __typename?: 'UserName', givenName: string, familyName: string } }> } | null, userErrors?: Array<{ __typename?: 'ClientError', code?: Error | null, field?: string | null, message?: string | null } | null> | null } | null };

export type UserDeactivateMutationVariables = Exact<{
  input: UserDeactivateInput;
}>;


export type UserDeactivateMutation = { __typename?: 'Mutation', userDeactivate?: { __typename?: 'PayloadOfBoolean', value?: boolean | null, userErrors?: Array<{ __typename?: 'ClientError', code?: Error | null, field?: string | null, message?: string | null } | null> | null } | null };

export type UserUnlockMutationVariables = Exact<{
  input: UserUnlockInput;
}>;


export type UserUnlockMutation = { __typename?: 'Mutation', userUnlock?: { __typename?: 'PayloadOfBoolean', value?: boolean | null, userErrors?: Array<{ __typename?: 'ClientError', code?: Error | null, field?: string | null, message?: string | null } | null> | null } | null };

export type UserReactivateMutationVariables = Exact<{
  input: UserReactivateInput;
}>;


export type UserReactivateMutation = { __typename?: 'Mutation', userReactivate?: { __typename?: 'PayloadOfBoolean', value?: boolean | null, userErrors?: Array<{ __typename?: 'ClientError', code?: Error | null, field?: string | null, message?: string | null } | null> | null } | null };

export type UserAccountsUsersQueryVariables = Exact<{
  where?: InputMaybe<UserFilterInput>;
}>;


export type UserAccountsUsersQuery = { __typename?: 'Query', currentUser: { __typename?: 'CurrentUser', id: string, homeAccount: { __typename?: 'CurrentUserAccount', id: string, account: { __typename?: 'Account', id: string, users: Array<{ __typename?: 'User', displayName: string, id: string, deactivated: boolean, locked: boolean, emailVerified: boolean, userName: string, type: UserType, email?: { __typename?: 'Email', value?: string | null } | null, name: { __typename?: 'UserName', givenName: string, familyName: string } }> } } } };

export type PaginatedUsersQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<UserFilterInput>;
  order?: InputMaybe<Array<UserSortInput> | UserSortInput>;
}>;


export type PaginatedUsersQuery = { __typename?: 'Query', currentUser: { __typename?: 'CurrentUser', id: string, homeAccount: { __typename?: 'CurrentUserAccount', id: string, account: { __typename?: 'Account', id: string, paginatedUsers?: { __typename?: 'PaginatedUsersConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, nodes?: Array<{ __typename?: 'User', displayName: string, id: string, locked: boolean, deactivated: boolean, emailVerified: boolean, userName: string, type: UserType, name: { __typename?: 'UserName', givenName: string, familyName: string } } | null> | null } | null } } } };

export type UserResetMfaMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type UserResetMfaMutation = { __typename?: 'Mutation', userResetMfa?: { __typename?: 'PayloadOfBoolean', value?: boolean | null, userErrors?: Array<{ __typename?: 'ClientError', code?: Error | null, field?: string | null, message?: string | null } | null> | null } | null };

export type ServiceClientNamesQueryVariables = Exact<{
  where?: InputMaybe<ServiceClientFilter>;
}>;


export type ServiceClientNamesQuery = { __typename?: 'Query', currentUser: { __typename?: 'CurrentUser', id: string, homeAccount: { __typename?: 'CurrentUserAccount', id: string, account: { __typename?: 'Account', id: string, serviceClients: Array<{ __typename?: 'ServiceClient', clientName?: string | null, clientId: string }> } } } };

export type UserFormEditServiceUserMutationVariables = Exact<{
  input: ServiceUserEditInput;
}>;


export type UserFormEditServiceUserMutation = { __typename?: 'Mutation', serviceUserEdit?: { __typename?: 'PayloadOfApplicationUser', value?: { __typename?: 'User', id: string, displayName: string, userName: string, emailVerified: boolean, name: { __typename?: 'UserName', givenName: string, familyName: string }, emails: Array<{ __typename?: 'Email', value?: string | null } | null>, groups: Array<{ __typename?: 'UserGroup', id: string, displayName: string }> } | null, userErrors?: Array<{ __typename?: 'ClientError', code?: Error | null, field?: string | null, message?: string | null } | null> | null } | null };

export type UserFormAddUserMutationVariables = Exact<{
  input: UserCreateInput;
}>;


export type UserFormAddUserMutation = { __typename?: 'Mutation', userCreate?: { __typename?: 'PayloadOfApplicationUser', value?: { __typename?: 'User', id: string, displayName: string, groups: Array<{ __typename?: 'UserGroup', id: string, displayName: string }> } | null, userErrors?: Array<{ __typename?: 'ClientError', code?: Error | null, field?: string | null, message?: string | null } | null> | null } | null };

export type ServiceUserFormAddUserMutationVariables = Exact<{
  input: ServiceUserCreateInput;
}>;


export type ServiceUserFormAddUserMutation = { __typename?: 'Mutation', serviceUserCreate?: { __typename?: 'PayloadOfApplicationUser', value?: { __typename?: 'User', id: string, displayName: string, groups: Array<{ __typename?: 'UserGroup', id: string, displayName: string }> } | null, userErrors?: Array<{ __typename?: 'ClientError', code?: Error | null, field?: string | null, message?: string | null } | null> | null } | null };

export type UserFormEditUserMutationVariables = Exact<{
  input: UserEditInput;
}>;


export type UserFormEditUserMutation = { __typename?: 'Mutation', userEdit?: { __typename?: 'PayloadOfApplicationUser', value?: { __typename?: 'User', id: string, displayName: string, userName: string, emailVerified: boolean, name: { __typename?: 'UserName', givenName: string, familyName: string }, emails: Array<{ __typename?: 'Email', value?: string | null } | null>, groups: Array<{ __typename?: 'UserGroup', id: string, displayName: string }> } | null, userErrors?: Array<{ __typename?: 'ClientError', code?: Error | null, field?: string | null, message?: string | null } | null> | null } | null };

export type UserFormResendVerificationEmailMutationVariables = Exact<{
  input: UserSetPasswordAndConfirmEmailInput;
}>;


export type UserFormResendVerificationEmailMutation = { __typename?: 'Mutation', userSetPasswordAndConfirmEmail?: { __typename?: 'PayloadOfBoolean', value?: boolean | null, userErrors?: Array<{ __typename?: 'ClientError', code?: Error | null, field?: string | null, message?: string | null } | null> | null } | null };

export type ResetUserPasswordMutationMutationVariables = Exact<{
  input: UserAdministratorResetPasswordInput;
}>;


export type ResetUserPasswordMutationMutation = { __typename?: 'Mutation', userAdministratorResetPassword?: { __typename?: 'PayloadOfBoolean', value?: boolean | null, userErrors?: Array<{ __typename?: 'ClientError', code?: Error | null, message?: string | null, field?: string | null } | null> | null } | null };

export type UserInformationQueryVariables = Exact<{
  id: Scalars['Uuid']['input'];
}>;


export type UserInformationQuery = { __typename?: 'Query', currentUser: { __typename?: 'CurrentUser', id: string, homeAccount: { __typename?: 'CurrentUserAccount', id: string, account: { __typename?: 'Account', id: string, users: Array<{ __typename?: 'User', id: string, displayName: string, type: UserType, userName: string, emailVerified: boolean, deactivated: boolean, locked: boolean, mfaEnabled: boolean, name: { __typename?: 'UserName', givenName: string, familyName: string }, emails: Array<{ __typename?: 'Email', value?: string | null } | null>, groups: Array<{ __typename?: 'UserGroup', id: string, displayName: string }>, account: { __typename?: 'Account', id: string, mfaSettings: { __typename?: 'MfaSettings', mfaEnabled: boolean } } }> } } } };

export type UserEmailAccountQueryVariables = Exact<{
  where?: InputMaybe<UserFilterInput>;
}>;


export type UserEmailAccountQuery = { __typename?: 'Query', currentUser: { __typename?: 'CurrentUser', id: string, homeAccount: { __typename?: 'CurrentUserAccount', id: string, account: { __typename?: 'Account', id: string, users: Array<{ __typename?: 'User', id: string, emails: Array<{ __typename?: 'Email', value?: string | null } | null> }> } } } };

export type UserNamesQueryVariables = Exact<{
  where?: InputMaybe<UserFilterInput>;
}>;


export type UserNamesQuery = { __typename?: 'Query', currentUser: { __typename?: 'CurrentUser', id: string, homeAccount: { __typename?: 'CurrentUserAccount', id: string, account: { __typename?: 'Account', id: string, users: Array<{ __typename?: 'User', id: string, userName: string }> } } } };

export type SignedUrlStatusQueryVariables = Exact<{
  url: Scalars['String']['input'];
}>;


export type SignedUrlStatusQuery = { __typename?: 'Query', onboardingSignedUrlStatus?: { __typename?: 'SignedUrl', status: SignedUrlStatus } | null };

export type SendAdministratorEmailMutationVariables = Exact<{
  adminEmail: Scalars['String']['input'];
  url: Scalars['String']['input'];
}>;


export type SendAdministratorEmailMutation = { __typename?: 'Mutation', onboardingContractSendAdminEmail?: { __typename?: 'PayloadOfSendAdminEmailResponse', userErrors?: Array<{ __typename?: 'onboardingClientError', code?: string | null, field?: string | null, message?: string | null } | null> | null, value?: { __typename?: 'SendAdminEmailResponse', id: string } | null } | null };

export type ExpiredLinkMutationVariables = Exact<{
  signedUrlFromTermEmail: Scalars['String']['input'];
}>;


export type ExpiredLinkMutation = { __typename?: 'Mutation', onboardingAccountResendTermEmailFromExpiredTermEmail?: { __typename?: 'PayloadOfResendExpiredTermEmailResponse', value?: { __typename?: 'ResendExpiredTermEmailResponse', isSuccessful: boolean } | null, userErrors?: Array<{ __typename?: 'onboardingClientError', code?: string | null, field?: string | null, message?: string | null } | null> | null } | null };

export type AcceptTermsMutationVariables = Exact<{
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  organizationName: Scalars['String']['input'];
  title: Scalars['String']['input'];
  url: Scalars['String']['input'];
  version: Scalars['String']['input'];
}>;


export type AcceptTermsMutation = { __typename?: 'Mutation', onboardingAccountAcceptTerms?: { __typename?: 'PayloadOfTerms', value?: { __typename?: 'Terms', accountId?: string | null, termAcceptedDateTime?: any | null } | null, userErrors?: Array<{ __typename?: 'onboardingClientError', message?: string | null } | null> | null } | null };

export type UpdateAdminEmailMutationVariables = Exact<{
  contractId: Scalars['String']['input'];
  updatedAdminEmail: Scalars['String']['input'];
}>;


export type UpdateAdminEmailMutation = { __typename?: 'Mutation', onboardingContractUpdateAdminEmail?: { __typename?: 'PayloadOfOnboardingContract', value?: { __typename?: 'onboardingContract', id: string, adminEmail?: string | null } | null, userErrors?: Array<{ __typename?: 'onboardingClientError', code?: string | null, field?: string | null, message?: string | null } | null> | null } | null };

export type RepublishOnboardAdminEventMutationVariables = Exact<{
  contractId: Scalars['String']['input'];
}>;


export type RepublishOnboardAdminEventMutation = { __typename?: 'Mutation', onboardingAccountRepublishAdminEvent?: { __typename?: 'PayloadOfOnboardingContract', value?: { __typename?: 'onboardingContract', id: string } | null, userErrors?: Array<{ __typename?: 'onboardingClientError', code?: string | null, field?: string | null, message?: string | null } | null> | null } | null };

export type ResendTermEmailMutationVariables = Exact<{
  contractId: Scalars['String']['input'];
}>;


export type ResendTermEmailMutation = { __typename?: 'Mutation', onboardingAccountResendTermEmail?: { __typename?: 'PayloadOfOnboardingContract', value?: { __typename?: 'onboardingContract', id: string } | null, userErrors?: Array<{ __typename?: 'onboardingClientError', code?: string | null, field?: string | null, message?: string | null } | null> | null } | null };

export type UpdateTermEmailMutationVariables = Exact<{
  contractId: Scalars['String']['input'];
  updatedTermEmail: Scalars['String']['input'];
}>;


export type UpdateTermEmailMutation = { __typename?: 'Mutation', onboardingAccountUpdateTermEmail?: { __typename?: 'PayloadOfOnboardingContract', value?: { __typename?: 'onboardingContract', id: string, contactEmail: string } | null, userErrors?: Array<{ __typename?: 'onboardingClientError', code?: string | null, field?: string | null, message?: string | null } | null> | null } | null };

export type AccountAppsQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountAppsQuery = { __typename?: 'Query', currentUser: { __typename?: 'CurrentUser', id: string, accountApps: Array<{ __typename?: 'CurrentUserAccountApp', id: string, appKey: string }> } };

export type OnboardingContractsQueryVariables = Exact<{
  contractId: Scalars['Uuid']['input'];
}>;


export type OnboardingContractsQuery = { __typename?: 'Query', onboardingContracts?: Array<{ __typename?: 'onboardingContract', id: string, messageId: string, status: ContractStatus, adminEmail?: string | null, contactEmail: string, hxpContractS3BucketName: string, hxpContractS3Key: string, sourceContractId: string, sourceContractSystem: SourceSystem, type: ContractType, contractLog: Array<{ __typename?: 'ContractLog', id: string, eventDateTime: any, userId: string, message: string }>, account: { __typename?: 'onboardingAccount', id: string, name: string, termEmail?: string | null, hxpAccountId?: string | null, sourceAccountId: string, sourceAccountSystem: SourceSystem }, supportAccount: { __typename?: 'onboardingAccount', id: string, name: string, sourceAccountId: string, sourceAccountSystem: SourceSystem }, onboardingSubscriptions: Array<{ __typename?: 'ContractSubscription', id: string, title: string, hxpEnvironmentId?: string | null, hxpEnvironmentTitle?: string | null, hxpSubscriptionId?: string | null }> } | null> | null };

export type RecentContractsQueryVariables = Exact<{
  createdAfter?: InputMaybe<Scalars['Instant']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}>;


export type RecentContractsQuery = { __typename?: 'Query', ContractAndAccountView?: { __typename?: 'ContractAndAccountViewConnection', pageInfo: { __typename?: 'PageInfo', startCursor?: string | null, endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean }, edges?: Array<{ __typename?: 'ContractAndAccountViewEdge', cursor: string, node?: { __typename?: 'ContractAndAccount', accountId: string, accountName: string, contractCreatedDateTime: any, contractId: string, contractState: ContractState, contractStatus: ContractStatus, contractType: ContractType, sourceAccountSystem: SourceSystem, sourceAccountId: string, sourceContractSystem: SourceSystem, sourceContractId: string } | null }> | null } | null };

export type ContractAndAccountViewQueryVariables = Exact<{
  where?: InputMaybe<ContractAndAccountFilterInput>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}>;


export type ContractAndAccountViewQuery = { __typename?: 'Query', ContractAndAccountView?: { __typename?: 'ContractAndAccountViewConnection', pageInfo: { __typename?: 'PageInfo', startCursor?: string | null, endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean }, edges?: Array<{ __typename?: 'ContractAndAccountViewEdge', cursor: string, node?: { __typename?: 'ContractAndAccount', accountId: string, accountName: string, contractCreatedDateTime: any, contractId: string, contractState: ContractState, contractStatus: ContractStatus, sourceAccountSystem: SourceSystem, sourceAccountId: string, sourceContractSystem: SourceSystem, sourceContractId: string, contractType: ContractType } | null }> | null } | null };

export type CurrentUserAppsQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserAppsQuery = { __typename?: 'Query', currentUser: { __typename?: 'CurrentUser', id: string, subscribedApps: Array<{ __typename?: 'CurrentUserSubscribedApp', id: string, environmentId: string, launchUrl?: string | null, provisioningStatus: SubscribedAppProvisioningStatus, app: { __typename?: 'App', id: string, localizedName: string, appType: AppType } }>, accountApps: Array<{ __typename?: 'CurrentUserAccountApp', id: string, launchUrl: string, provisioningStatus: AccountAppProvisioningStatus, app: { __typename?: 'App', id: string, localizedName: string } }> } };

export type UserPortalEnvironmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type UserPortalEnvironmentsQuery = { __typename?: 'Query', currentUser: { __typename?: 'CurrentUser', id: string, subscribedApps: Array<{ __typename?: 'CurrentUserSubscribedApp', id: string, environment: { __typename?: 'CurrentUserEnvironment', id: string, name: string } }> } };

export type AccountAuditEventsQueryVariables = Exact<{
  startDate: Scalars['Instant']['input'];
  endDate: Scalars['Instant']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<AuditEventSortInput> | AuditEventSortInput>;
}>;


export type AccountAuditEventsQuery = { __typename?: 'Query', currentUser: { __typename?: 'CurrentUser', id: string, homeAccount: { __typename?: 'CurrentUserAccount', id: string, account: { __typename?: 'Account', id: string, auditEvents?: { __typename?: 'AuditEventsConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, nodes?: Array<{ __typename?: 'AuditEvent', type: string, name?: string | null, timestamp: any, id: any, actor: { __typename?: 'Actor', type: UserAuditType, id: string }, relatedObject?: { __typename?: 'AuditObject', id?: string | null, scope?: string | null } | null }> | null } | null } } } };

export type EnvironmentAuditEventsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  startDate: Scalars['Instant']['input'];
  endDate: Scalars['Instant']['input'];
  order?: InputMaybe<Array<AuditEventSortInput> | AuditEventSortInput>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
}>;


export type EnvironmentAuditEventsQuery = { __typename?: 'Query', currentUser: { __typename?: 'CurrentUser', id: string, homeAccount: { __typename?: 'CurrentUserAccount', id: string, account: { __typename?: 'Account', id: string, environment?: { __typename?: 'Environment', auditEvents?: { __typename?: 'AuditEventsConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, nodes?: Array<{ __typename?: 'AuditEvent', type: string, name?: string | null, timestamp: any, id: any, actor: { __typename?: 'Actor', type: UserAuditType, id: string }, relatedObject?: { __typename?: 'AuditObject', id?: string | null, scope?: string | null } | null }> | null } | null } | null } } } };

export type UserAttributesQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;


export type UserAttributesQuery = { __typename?: 'Query', currentUser: { __typename?: 'CurrentUser', id: string, homeAccount: { __typename?: 'CurrentUserAccount', id: string, account: { __typename?: 'Account', id: string, userAttributes?: { __typename?: 'UserAttributesConnection', nodes?: Array<{ __typename?: 'UserAttribute', key: string, displayName?: string | null } | null> | null, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean } } | null } } } };

export type EnvironmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type EnvironmentsQuery = { __typename?: 'Query', currentUser: { __typename?: 'CurrentUser', id: string, homeAccount: { __typename?: 'CurrentUserAccount', id: string, account: { __typename?: 'Account', id: string, name: string, environments: Array<{ __typename?: 'Environment', id: string, name: string, key: string }> } } } };

export type SubscribedAppsQueryVariables = Exact<{ [key: string]: never; }>;


export type SubscribedAppsQuery = { __typename?: 'Query', currentUser: { __typename?: 'CurrentUser', id: string, subscribedApps: Array<{ __typename?: 'CurrentUserSubscribedApp', id: string, environmentId: string, app: { __typename?: 'App', id: string, key: string, appType: AppType } }> } };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { __typename?: 'Query', currentUser: { __typename?: 'CurrentUser', id: string } };

export type AccountIdQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountIdQuery = { __typename?: 'Query', currentUser: { __typename?: 'CurrentUser', id: string, homeAccount: { __typename?: 'CurrentUserAccount', id: string, account: { __typename?: 'Account', id: string } } } };

export type SwitcherAppsQueryVariables = Exact<{ [key: string]: never; }>;


export type SwitcherAppsQuery = { __typename?: 'Query', currentUser: { __typename?: 'CurrentUser', id: string, accountApps: Array<{ __typename?: 'CurrentUserAccountApp', id: string, launchUrl: string, app: { __typename?: 'App', id: string, localizedName: string } }> } };

export type AccountAppListQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountAppListQuery = { __typename?: 'Query', currentUser: { __typename?: 'CurrentUser', id: string, homeAccount: { __typename?: 'CurrentUserAccount', id: string, account: { __typename?: 'Account', id: string, accountApps: Array<{ __typename?: 'AccountApp', id: string, app: { __typename?: 'App', id: string, localizedName: string } }> } } } };

export type RoleUserGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type RoleUserGroupsQuery = { __typename?: 'Query', currentUser: { __typename?: 'CurrentUser', id: string, homeAccount: { __typename?: 'CurrentUserAccount', id: string, account: { __typename?: 'Account', id: string, accountApps: Array<{ __typename?: 'AccountApp', id: string, appKey: string, app: { __typename?: 'App', id: string, localizedName: string }, roles: Array<{ __typename?: 'AccountAppRole', role: { __typename?: 'Role', key: string, name: string, id: string }, userGroups: Array<{ __typename?: 'UserGroup', id: string, displayName: string }> }> }> } } } };

export type RoleAssignUserGroupsForAccountMutationVariables = Exact<{
  input: RoleAssignUserGroupsForAccountInput;
}>;


export type RoleAssignUserGroupsForAccountMutation = { __typename?: 'Mutation', roleAssignUserGroupsForAccount?: { __typename?: 'PayloadOfBoolean', value?: boolean | null, userErrors?: Array<{ __typename?: 'ClientError', code?: Error | null, field?: string | null, message?: string | null } | null> | null } | null };

export type AccountDetailsOverviewQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountDetailsOverviewQuery = { __typename?: 'Query', currentUser: { __typename?: 'CurrentUser', id: string, homeAccount: { __typename?: 'CurrentUserAccount', id: string, account: { __typename?: 'Account', id: string, name: string, hsiNumber?: number | null, sourceAccountSystem: SourceSystem, sourceAccountId: string } } } };

export type AccountDetailSubscriptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountDetailSubscriptionsQuery = { __typename?: 'Query', currentUser: { __typename?: 'CurrentUser', id: string, homeAccount: { __typename?: 'CurrentUserAccount', id: string, account: { __typename?: 'Account', id: string, subscriptions: Array<{ __typename?: 'SubscriptionDetail', id: string, title: string, status: SubscriptionStatus, type: SubscriptionType, environment?: { __typename?: 'Environment', id: string, name: string } | null }> } } } };

export type AccountDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountDetailsQuery = { __typename?: 'Query', currentUser: { __typename?: 'CurrentUser', id: string, homeAccount: { __typename?: 'CurrentUserAccount', id: string, account: { __typename?: 'Account', id: string, name: string } } } };

export type ApplicationDetailsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ApplicationDetailsQuery = { __typename?: 'Query', currentUser: { __typename?: 'CurrentUser', id: string, homeAccount: { __typename?: 'CurrentUserAccount', id: string, account: { __typename?: 'Account', id: string, subscribedApp?: { __typename?: 'SubscribedApp', id: string, environment?: { __typename?: 'Environment', id: string, name: string } | null, app: { __typename?: 'App', id: string, localizedName: string, appType: AppType } } | null } } } };

export type AppUserRightsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type AppUserRightsQuery = { __typename?: 'Query', currentUser: { __typename?: 'CurrentUser', id: string, homeAccount: { __typename?: 'CurrentUserAccount', id: string, account: { __typename?: 'Account', id: string, subscribedApp?: { __typename?: 'SubscribedApp', id: string, appKey: string, environmentRoles: Array<{ __typename?: 'EnvironmentAppRole', role: { __typename?: 'Role', key: string, name: string, id: string }, userGroups: Array<{ __typename?: 'UserGroup', id: string, displayName: string }> }>, environment?: { __typename?: 'Environment', id: string } | null } | null } } } };

export type RoleAssignUserGroupsForEnvironmentMutationVariables = Exact<{
  input: RoleAssignUserGroupsForEnvironmentInput;
}>;


export type RoleAssignUserGroupsForEnvironmentMutation = { __typename?: 'Mutation', roleAssignUserGroupsForEnvironment?: { __typename?: 'PayloadOfBoolean', value?: boolean | null, userErrors?: Array<{ __typename?: 'ClientError', code?: Error | null, field?: string | null, message?: string | null } | null> | null } | null };

export type ApplicationOverviewQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ApplicationOverviewQuery = { __typename?: 'Query', currentUser: { __typename?: 'CurrentUser', id: string, homeAccount: { __typename?: 'CurrentUserAccount', id: string, account: { __typename?: 'Account', id: string, subscribedApp?: { __typename?: 'SubscribedApp', id: string, subscription: { __typename?: 'SubscriptionDetail', id: string, title: string }, app: { __typename?: 'App', id: string, localizedName: string, localizedDescription: string } } | null } } } };

export type EnvironmentApplicationListQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type EnvironmentApplicationListQuery = { __typename?: 'Query', currentUser: { __typename?: 'CurrentUser', id: string, homeAccount: { __typename?: 'CurrentUserAccount', id: string, account: { __typename?: 'Account', id: string, environment?: { __typename?: 'Environment', id: string, name: string, subscriptions: Array<{ __typename?: 'SubscriptionDetail', id: string, title: string, status: SubscriptionStatus, subscribedApps: Array<{ __typename?: 'SubscribedApp', id: string, app: { __typename?: 'App', id: string, key: string, localizedName: string, appType: AppType } }> }> } | null } } } };

export type SubscriptionsForAccountQueryVariables = Exact<{ [key: string]: never; }>;


export type SubscriptionsForAccountQuery = { __typename?: 'Query', currentUser: { __typename?: 'CurrentUser', id: string, homeAccount: { __typename?: 'CurrentUserAccount', id: string, account: { __typename?: 'Account', id: string, subscriptions: Array<{ __typename?: 'SubscriptionDetail', id: string, title: string, status: SubscriptionStatus, subscribedApps: Array<{ __typename?: 'SubscribedApp', id: string, appKey: string, app: { __typename?: 'App', id: string, localizedName: string, appType: AppType } }>, environment?: { __typename?: 'Environment', id: string, name: string } | null }> } } } };

export type SubscriptionForPollingQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type SubscriptionForPollingQuery = { __typename?: 'Query', subscriptionById?: { __typename?: 'SubscriptionDetail', id: string, title: string, status: SubscriptionStatus, subscribedApps: Array<{ __typename?: 'SubscribedApp', id: string, provisioningStatus: SubscribedAppProvisioningStatus }>, subscribedAccountApps: Array<{ __typename?: 'AccountApp', id: string, provisioningStatus: AccountAppProvisioningStatus }> } | null };

export type EnvironmentCreateMutationVariables = Exact<{
  accountId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
}>;


export type EnvironmentCreateMutation = { __typename?: 'Mutation', environmentCreate: { __typename?: 'PayloadOfEnvironment', userErrors?: Array<{ __typename?: 'SubscriptionsClientError', code?: string | null, field?: string | null, message?: string | null } | null> | null, value?: { __typename?: 'Environment', id: string, name: string, accountId: string } | null } };

export type SubscriptionOverviewQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type SubscriptionOverviewQuery = { __typename?: 'Query', subscriptionById?: { __typename?: 'SubscriptionDetail', id: string, decommissionedDate?: any | null, endDate: any, startDate: any, status: SubscriptionStatus, title: string, type: SubscriptionType, environment?: { __typename?: 'Environment', id: string, name: string } | null, subscribedApps: Array<{ __typename?: 'SubscribedApp', id: string, provisioningStatus: SubscribedAppProvisioningStatus, app: { __typename?: 'App', id: string, localizedName: string, localizedDescription: string, appType: AppType } }>, subscribedAccountApps: Array<{ __typename?: 'AccountApp', id: string, provisioningStatus: AccountAppProvisioningStatus, app: { __typename?: 'App', id: string, localizedName: string, localizedDescription: string } }> } | null };

export type SubscriptionOverviewEnvironmentsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type SubscriptionOverviewEnvironmentsQuery = { __typename?: 'Query', subscriptionById?: { __typename?: 'SubscriptionDetail', id: string, contract?: { __typename?: 'Contract', account: { __typename?: 'Account', id: string, environments: Array<{ __typename?: 'Environment', id: string, name: string }> } } | null } | null };

export type SetSubscriptionEnvironmentMutationVariables = Exact<{
  subscriptionId: Scalars['ID']['input'];
  environmentId: Scalars['ID']['input'];
}>;


export type SetSubscriptionEnvironmentMutation = { __typename?: 'Mutation', subscriptionActivateWithEnvironment: { __typename?: 'PayloadOfSubscriptionDetail', userErrors?: Array<{ __typename?: 'SubscriptionsClientError', code?: string | null, field?: string | null, message?: string | null } | null> | null, value?: { __typename?: 'SubscriptionDetail', id: string, environment?: { __typename?: 'Environment', id: string } | null } | null } };

export type SubscriptionDetailsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type SubscriptionDetailsQuery = { __typename?: 'Query', subscriptionById?: { __typename?: 'SubscriptionDetail', id: string, title: string, environment?: { __typename?: 'Environment', id: string, name: string } | null } | null };

export type SubscriptionListQueryVariables = Exact<{
  environmentId: Scalars['ID']['input'];
}>;


export type SubscriptionListQuery = { __typename?: 'Query', currentUser: { __typename?: 'CurrentUser', id: string, homeAccount: { __typename?: 'CurrentUserAccount', id: string, account: { __typename?: 'Account', id: string, environment?: { __typename?: 'Environment', id: string, name: string, subscriptions: Array<{ __typename?: 'SubscriptionDetail', id: string, title: string, type: SubscriptionType, status: SubscriptionStatus }> } | null } } } };

export type SubscriptionUsageQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type SubscriptionUsageQuery = { __typename?: 'Query', subscriptionById?: { __typename?: 'SubscriptionDetail', id: string, currentCycleBillableUsage: Array<{ __typename?: 'BillableUsage', descriptionLocalizationKey?: string | null, nameLocalizationKey?: string | null, pricingStrategy: UsagePricingStrategy, strategy: { __typename?: 'Allotment', allotmentSize?: number | null, overageCycleCount?: number | null, cycleCount: number, cyclePeriod: UsageCyclePeriod, cyclePeriodEndDate: any, cyclePeriodStartDate: any } | { __typename?: 'Tiered', cycleCount: number, cyclePeriod: UsageCyclePeriod, cyclePeriodEndDate: any, cyclePeriodStartDate: any } }> } | null };

export const NewSubscriptionsDocument = gql`
    query NewSubscriptions {
  currentUser {
    id
    homeAccount {
      id
      account {
        id
        subscriptions {
          id
          createdDate
          status
          environment {
            id
          }
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class NewSubscriptionsGQL extends Apollo.Query<NewSubscriptionsQuery, NewSubscriptionsQueryVariables> {
    document = NewSubscriptionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MfaSettingsDocument = gql`
    query MfaSettings {
  currentUser {
    id
    homeAccount {
      id
      account {
        id
        mfaSettings {
          mfaEnabled
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MfaSettingsGQL extends Apollo.Query<MfaSettingsQuery, MfaSettingsQueryVariables> {
    document = MfaSettingsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SetMfaSettingsDocument = gql`
    mutation SetMfaSettings($mfaSettings: MfaSettingsEditInput!) {
  mfaSettingsEdit(input: $mfaSettings) {
    userErrors {
      code
      field
      message
    }
    value {
      mfaEnabled
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetMfaSettingsGQL extends Apollo.Mutation<SetMfaSettingsMutation, SetMfaSettingsMutationVariables> {
    document = SetMfaSettingsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ServiceClientCreateDocument = gql`
    mutation ServiceClientCreate($input: ServiceClientCreateInput!) {
  serviceClientCreate(input: $input) {
    value {
      clientId
      secretValue
      user {
        userName
        id
      }
    }
    userErrors {
      code
      field
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ServiceClientCreateGQL extends Apollo.Mutation<ServiceClientCreateMutation, ServiceClientCreateMutationVariables> {
    document = ServiceClientCreateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const WebServerClientCreateDocument = gql`
    mutation WebServerClientCreate($input: WebServerClientCreateInput!) {
  webServerClientCreate(input: $input) {
    value {
      clientId
      secretValue
    }
    userErrors {
      code
      field
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class WebServerClientCreateGQL extends Apollo.Mutation<WebServerClientCreateMutation, WebServerClientCreateMutationVariables> {
    document = WebServerClientCreateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ExternalAppsDocument = gql`
    query ExternalApps($webServerClientFilter: WebServerClientFilter, $javaScriptClientFilter: JavaScriptClientFilter, $serviceClientFilter: ServiceClientFilter) {
  currentUser {
    id
    homeAccount {
      id
      account {
        id
        serviceClients(where: $serviceClientFilter) {
          clientId
          clientName
          allowedScopes
          environmentId
          appKey
          secret {
            createdOn
          }
          user {
            userName
            id
          }
        }
        webServerClients(where: $webServerClientFilter) {
          clientId
          clientName
          secret {
            createdOn
          }
          postLogoutRedirectUris
          redirectUris
          allowedScopes
          environmentId
          appKey
        }
        javaScriptClients(where: $javaScriptClientFilter) {
          clientId
          clientName
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ExternalAppsGQL extends Apollo.Query<ExternalAppsQuery, ExternalAppsQueryVariables> {
    document = ExternalAppsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CheckExternalAppNameDocument = gql`
    query CheckExternalAppName($webServerClientFilter: WebServerClientFilter, $javaScriptClientFilter: JavaScriptClientFilter, $serviceClientFilter: ServiceClientFilter) {
  currentUser {
    id
    homeAccount {
      id
      account {
        id
        serviceClients(where: $serviceClientFilter) {
          clientName
        }
        webServerClients(where: $webServerClientFilter) {
          clientName
        }
        javaScriptClients(where: $javaScriptClientFilter) {
          clientName
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CheckExternalAppNameGQL extends Apollo.Query<CheckExternalAppNameQuery, CheckExternalAppNameQueryVariables> {
    document = CheckExternalAppNameDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveServiceClientDocument = gql`
    mutation SaveServiceClient($serviceClientEditInput: ServiceClientEditInput!) {
  serviceClientEdit(input: $serviceClientEditInput) {
    value {
      clientId
      clientName
      user {
        id
        userName
      }
    }
    userErrors {
      code
      field
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveServiceClientGQL extends Apollo.Mutation<SaveServiceClientMutation, SaveServiceClientMutationVariables> {
    document = SaveServiceClientDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PatchServiceClientDocument = gql`
    mutation PatchServiceClient($input: ServiceClientPatchInput!) {
  serviceClientPatch(input: $input) {
    value {
      clientId
    }
    userErrors {
      code
      field
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PatchServiceClientGQL extends Apollo.Mutation<PatchServiceClientMutation, PatchServiceClientMutationVariables> {
    document = PatchServiceClientDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AccountClientDeleteDocument = gql`
    mutation AccountClientDelete($accountClientDeleteInput: AccountClientDeleteInput!) {
  accountClientDelete(input: $accountClientDeleteInput) {
    userErrors {
      code
      field
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AccountClientDeleteGQL extends Apollo.Mutation<AccountClientDeleteMutation, AccountClientDeleteMutationVariables> {
    document = AccountClientDeleteDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AccountClientSecretResetDocument = gql`
    mutation AccountClientSecretReset($accountClientSecretInput: AccountClientSecretResetInput!) {
  accountClientSecretReset(input: $accountClientSecretInput) {
    value {
      secretValue
    }
    userErrors {
      code
      field
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AccountClientSecretResetGQL extends Apollo.Mutation<AccountClientSecretResetMutation, AccountClientSecretResetMutationVariables> {
    document = AccountClientSecretResetDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AllowedScopesDocument = gql`
    query AllowedScopes {
  accountClientScopes {
    scope
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AllowedScopesGQL extends Apollo.Query<AllowedScopesQuery, AllowedScopesQueryVariables> {
    document = AllowedScopesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const WebServerClientPatchInputDocument = gql`
    mutation WebServerClientPatchInput($input: WebServerClientPatchInput!) {
  webServerClientPatch(input: $input) {
    value {
      clientId
      clientName
      postLogoutRedirectUris
      redirectUris
    }
    userErrors {
      code
      field
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class WebServerClientPatchInputGQL extends Apollo.Mutation<WebServerClientPatchInputMutation, WebServerClientPatchInputMutationVariables> {
    document = WebServerClientPatchInputDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IdentityProvidersDocument = gql`
    query IdentityProviders {
  currentUser {
    id
    homeAccount {
      id
      account {
        id
        federationProviders {
          displayName
          providerId
          enabled
          samlConfiguration {
            issuerEntityId
          }
          federationEmailDomainRules {
            domain
          }
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class IdentityProvidersGQL extends Apollo.Query<IdentityProvidersQuery, IdentityProvidersQueryVariables> {
    document = IdentityProvidersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IdentityProviderDocument = gql`
    query IdentityProvider($where: FederationProviderFilterInput) {
  currentUser {
    id
    homeAccount {
      id
      account {
        id
        federationProviders(where: $where) {
          displayName
          providerId
          enabled
          providerType
          samlConfiguration {
            issuerEntityId
            requestedAssertionBinding
            authenticationRequestBinding
            minimumAssertionSignatureAlgorithm
            metadataLocationOverride
          }
          claims {
            externalUserIdClaim
            emailClaim
            userNameClaim
            firstNameClaim
            lastNameClaim
            groupClaim
          }
          userAttributeAssignments {
            userAttribute {
              key
              displayName
            }
            sourceClaimType
          }
          federationEmailDomainRules {
            domain
            enableLocalLogin
            key
          }
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class IdentityProviderGQL extends Apollo.Query<IdentityProviderQuery, IdentityProviderQueryVariables> {
    document = IdentityProviderDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EmailDomainsDocument = gql`
    query EmailDomains {
  currentUser {
    id
    homeAccount {
      id
      account {
        id
        federationEmailDomainRules {
          domain
          enableLocalLogin
          key
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EmailDomainsGQL extends Apollo.Query<EmailDomainsQuery, EmailDomainsQueryVariables> {
    document = EmailDomainsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteEmailDomainDocument = gql`
    mutation DeleteEmailDomain($input: FederationEmailDomainRuleDeleteInput!) {
  federationEmailDomainRuleDelete(input: $input) {
    userErrors {
      code
      message
      field
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteEmailDomainGQL extends Apollo.Mutation<DeleteEmailDomainMutation, DeleteEmailDomainMutationVariables> {
    document = DeleteEmailDomainDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PatchIdentityProviderDocument = gql`
    mutation PatchIdentityProvider($input: FederationProviderPatchInput!) {
  federationProviderPatch(input: $input) {
    userErrors {
      code
      message
      field
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PatchIdentityProviderGQL extends Apollo.Mutation<PatchIdentityProviderMutation, PatchIdentityProviderMutationVariables> {
    document = PatchIdentityProviderDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PatchSamlConfigurationDocument = gql`
    mutation PatchSamlConfiguration($input: SamlConfigurationPatchInput!) {
  samlConfigurationPatch(input: $input) {
    userErrors {
      code
      message
      field
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PatchSamlConfigurationGQL extends Apollo.Mutation<PatchSamlConfigurationMutation, PatchSamlConfigurationMutationVariables> {
    document = PatchSamlConfigurationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FederationProviderCreateDocument = gql`
    mutation FederationProviderCreate($input: FederationProviderCreateInput!) {
  federationProviderCreate(input: $input) {
    value {
      providerId
    }
    userErrors {
      code
      message
      field
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FederationProviderCreateGQL extends Apollo.Mutation<FederationProviderCreateMutation, FederationProviderCreateMutationVariables> {
    document = FederationProviderCreateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SamlConfigurationCreateDocument = gql`
    mutation SamlConfigurationCreate($input: SamlConfigurationCreateInput!) {
  samlConfigurationCreate(input: $input) {
    value {
      providerId
    }
    userErrors {
      code
      message
      field
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SamlConfigurationCreateGQL extends Apollo.Mutation<SamlConfigurationCreateMutation, SamlConfigurationCreateMutationVariables> {
    document = SamlConfigurationCreateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteIdentityProviderDocument = gql`
    mutation DeleteIdentityProvider($input: FederationProviderDeleteInput!) {
  federationProviderDelete(input: $input) {
    value
    userErrors {
      code
      message
      field
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteIdentityProviderGQL extends Apollo.Mutation<DeleteIdentityProviderMutation, DeleteIdentityProviderMutationVariables> {
    document = DeleteIdentityProviderDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FederationEmailDomainRuleCreateDocument = gql`
    mutation FederationEmailDomainRuleCreate($input: FederationEmailDomainRuleCreateInput!) {
  federationEmailDomainRuleCreate(input: $input) {
    value {
      key
    }
    userErrors {
      code
      message
      field
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FederationEmailDomainRuleCreateGQL extends Apollo.Mutation<FederationEmailDomainRuleCreateMutation, FederationEmailDomainRuleCreateMutationVariables> {
    document = FederationEmailDomainRuleCreateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FederationProviderEmailDomainRulesUpdateDocument = gql`
    mutation FederationProviderEmailDomainRulesUpdate($input: FederationProviderEmailDomainRulesUpdateInput!) {
  federationProviderEmailDomainRulesUpdate(input: $input) {
    value
    userErrors {
      code
      field
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FederationProviderEmailDomainRulesUpdateGQL extends Apollo.Mutation<FederationProviderEmailDomainRulesUpdateMutation, FederationProviderEmailDomainRulesUpdateMutationVariables> {
    document = FederationProviderEmailDomainRulesUpdateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FederationEmailDomainRuleEditDocument = gql`
    mutation FederationEmailDomainRuleEdit($input: FederationEmailDomainRuleEditInput!) {
  federationEmailDomainRuleEdit(input: $input) {
    value {
      key
    }
    userErrors {
      code
      message
      field
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FederationEmailDomainRuleEditGQL extends Apollo.Mutation<FederationEmailDomainRuleEditMutation, FederationEmailDomainRuleEditMutationVariables> {
    document = FederationEmailDomainRuleEditDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserGroupsDocument = gql`
    query UserGroups($where: UserGroupFilterInput) {
  currentUser {
    id
    homeAccount {
      id
      account {
        id
        groups(where: $where) {
          id
          displayName
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserGroupsGQL extends Apollo.Query<UserGroupsQuery, UserGroupsQueryVariables> {
    document = UserGroupsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserGroupInformationDocument = gql`
    query UserGroupInformation($where: UserGroupFilterInput!) {
  currentUser {
    id
    homeAccount {
      id
      account {
        id
        groups(where: $where) {
          id
          displayName
          groupType
          members(include: {deactivated: true}) {
            id
            displayName
            userName
            name {
              givenName
              familyName
            }
          }
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserGroupInformationGQL extends Apollo.Query<UserGroupInformationQuery, UserGroupInformationQueryVariables> {
    document = UserGroupInformationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateUserGroupDocument = gql`
    mutation CreateUserGroup($input: UserGroupCreateInput!) {
  userGroupCreate(input: $input) {
    value {
      id
      displayName
    }
    userErrors {
      code
      field
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateUserGroupGQL extends Apollo.Mutation<CreateUserGroupMutation, CreateUserGroupMutationVariables> {
    document = CreateUserGroupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EditUserGroupDocument = gql`
    mutation EditUserGroup($input: UserGroupEditInput!) {
  userGroupEdit(input: $input) {
    value {
      id
      displayName
      members(include: {deactivated: true}) {
        id
        displayName
        userName
        name {
          givenName
          familyName
        }
      }
    }
    userErrors {
      code
      field
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EditUserGroupGQL extends Apollo.Mutation<EditUserGroupMutation, EditUserGroupMutationVariables> {
    document = EditUserGroupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserDeactivateDocument = gql`
    mutation UserDeactivate($input: UserDeactivateInput!) {
  userDeactivate(input: $input) {
    value
    userErrors {
      code
      field
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserDeactivateGQL extends Apollo.Mutation<UserDeactivateMutation, UserDeactivateMutationVariables> {
    document = UserDeactivateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserUnlockDocument = gql`
    mutation UserUnlock($input: UserUnlockInput!) {
  userUnlock(input: $input) {
    value
    userErrors {
      code
      field
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserUnlockGQL extends Apollo.Mutation<UserUnlockMutation, UserUnlockMutationVariables> {
    document = UserUnlockDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserReactivateDocument = gql`
    mutation UserReactivate($input: UserReactivateInput!) {
  userReactivate(input: $input) {
    value
    userErrors {
      code
      field
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserReactivateGQL extends Apollo.Mutation<UserReactivateMutation, UserReactivateMutationVariables> {
    document = UserReactivateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserAccountsUsersDocument = gql`
    query UserAccountsUsers($where: UserFilterInput) {
  currentUser {
    id
    homeAccount {
      id
      account {
        id
        users(include: {deactivated: true}, where: $where) {
          displayName
          id
          email {
            value
          }
          deactivated
          locked
          emailVerified
          userName
          name {
            givenName
            familyName
          }
          type
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserAccountsUsersGQL extends Apollo.Query<UserAccountsUsersQuery, UserAccountsUsersQueryVariables> {
    document = UserAccountsUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PaginatedUsersDocument = gql`
    query PaginatedUsers($first: Int, $after: String, $where: UserFilterInput, $order: [UserSortInput!]) {
  currentUser {
    id
    homeAccount {
      id
      account {
        id
        paginatedUsers(
          include: {deactivated: true}
          where: $where
          first: $first
          order: $order
          after: $after
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          nodes {
            displayName
            id
            locked
            deactivated
            emailVerified
            userName
            name {
              givenName
              familyName
            }
            type
          }
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PaginatedUsersGQL extends Apollo.Query<PaginatedUsersQuery, PaginatedUsersQueryVariables> {
    document = PaginatedUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserResetMfaDocument = gql`
    mutation UserResetMfa($id: ID!) {
  userResetMfa(input: {userId: $id}) {
    userErrors {
      code
      field
      message
    }
    value
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserResetMfaGQL extends Apollo.Mutation<UserResetMfaMutation, UserResetMfaMutationVariables> {
    document = UserResetMfaDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ServiceClientNamesDocument = gql`
    query ServiceClientNames($where: ServiceClientFilter) {
  currentUser {
    id
    homeAccount {
      id
      account {
        id
        serviceClients(where: $where) {
          clientName
          clientId
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ServiceClientNamesGQL extends Apollo.Query<ServiceClientNamesQuery, ServiceClientNamesQueryVariables> {
    document = ServiceClientNamesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserFormEditServiceUserDocument = gql`
    mutation UserFormEditServiceUser($input: ServiceUserEditInput!) {
  serviceUserEdit(input: $input) {
    value {
      id
      name {
        givenName
        familyName
      }
      displayName
      emails {
        value
      }
      userName
      emailVerified
      groups {
        id
        displayName
      }
    }
    userErrors {
      code
      field
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserFormEditServiceUserGQL extends Apollo.Mutation<UserFormEditServiceUserMutation, UserFormEditServiceUserMutationVariables> {
    document = UserFormEditServiceUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserFormAddUserDocument = gql`
    mutation UserFormAddUser($input: UserCreateInput!) {
  userCreate(input: $input) {
    value {
      id
      displayName
      groups {
        id
        displayName
      }
    }
    userErrors {
      code
      field
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserFormAddUserGQL extends Apollo.Mutation<UserFormAddUserMutation, UserFormAddUserMutationVariables> {
    document = UserFormAddUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ServiceUserFormAddUserDocument = gql`
    mutation ServiceUserFormAddUser($input: ServiceUserCreateInput!) {
  serviceUserCreate(input: $input) {
    value {
      id
      displayName
      groups {
        id
        displayName
      }
    }
    userErrors {
      code
      field
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ServiceUserFormAddUserGQL extends Apollo.Mutation<ServiceUserFormAddUserMutation, ServiceUserFormAddUserMutationVariables> {
    document = ServiceUserFormAddUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserFormEditUserDocument = gql`
    mutation UserFormEditUser($input: UserEditInput!) {
  userEdit(input: $input) {
    value {
      id
      name {
        givenName
        familyName
      }
      displayName
      emails {
        value
      }
      userName
      emailVerified
      groups {
        id
        displayName
      }
    }
    userErrors {
      code
      field
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserFormEditUserGQL extends Apollo.Mutation<UserFormEditUserMutation, UserFormEditUserMutationVariables> {
    document = UserFormEditUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserFormResendVerificationEmailDocument = gql`
    mutation UserFormResendVerificationEmail($input: UserSetPasswordAndConfirmEmailInput!) {
  userSetPasswordAndConfirmEmail(input: $input) {
    value
    userErrors {
      code
      field
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserFormResendVerificationEmailGQL extends Apollo.Mutation<UserFormResendVerificationEmailMutation, UserFormResendVerificationEmailMutationVariables> {
    document = UserFormResendVerificationEmailDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ResetUserPasswordMutationDocument = gql`
    mutation ResetUserPasswordMutation($input: UserAdministratorResetPasswordInput!) {
  userAdministratorResetPassword(input: $input) {
    value
    userErrors {
      code
      message
      field
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ResetUserPasswordMutationGQL extends Apollo.Mutation<ResetUserPasswordMutationMutation, ResetUserPasswordMutationMutationVariables> {
    document = ResetUserPasswordMutationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserInformationDocument = gql`
    query UserInformation($id: Uuid!) {
  currentUser {
    id
    homeAccount {
      id
      account {
        id
        users(include: {deactivated: true}, where: {id: {eq: $id}}) {
          id
          displayName
          type
          name {
            givenName
            familyName
          }
          emails {
            value
          }
          userName
          emailVerified
          deactivated
          locked
          mfaEnabled
          groups {
            id
            displayName
          }
          account {
            id
            mfaSettings {
              mfaEnabled
            }
          }
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserInformationGQL extends Apollo.Query<UserInformationQuery, UserInformationQueryVariables> {
    document = UserInformationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserEmailAccountDocument = gql`
    query UserEmailAccount($where: UserFilterInput) {
  currentUser {
    id
    homeAccount {
      id
      account {
        id
        users(where: $where) {
          id
          emails {
            value
          }
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserEmailAccountGQL extends Apollo.Query<UserEmailAccountQuery, UserEmailAccountQueryVariables> {
    document = UserEmailAccountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserNamesDocument = gql`
    query UserNames($where: UserFilterInput) {
  currentUser {
    id
    homeAccount {
      id
      account {
        id
        users(include: {deactivated: true}, where: $where) {
          id
          userName
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserNamesGQL extends Apollo.Query<UserNamesQuery, UserNamesQueryVariables> {
    document = UserNamesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SignedUrlStatusDocument = gql`
    query SignedUrlStatus($url: String!) {
  onboardingSignedUrlStatus(url: $url) {
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SignedUrlStatusGQL extends Apollo.Query<SignedUrlStatusQuery, SignedUrlStatusQueryVariables> {
    document = SignedUrlStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SendAdministratorEmailDocument = gql`
    mutation SendAdministratorEmail($adminEmail: String!, $url: String!) {
  onboardingContractSendAdminEmail(adminEmail: $adminEmail, url: $url) {
    userErrors {
      code
      field
      message
    }
    value {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SendAdministratorEmailGQL extends Apollo.Mutation<SendAdministratorEmailMutation, SendAdministratorEmailMutationVariables> {
    document = SendAdministratorEmailDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ExpiredLinkDocument = gql`
    mutation ExpiredLink($signedUrlFromTermEmail: String!) {
  onboardingAccountResendTermEmailFromExpiredTermEmail(
    signedUrlFromTermEmail: $signedUrlFromTermEmail
  ) {
    value {
      isSuccessful
    }
    userErrors {
      code
      field
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ExpiredLinkGQL extends Apollo.Mutation<ExpiredLinkMutation, ExpiredLinkMutationVariables> {
    document = ExpiredLinkDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AcceptTermsDocument = gql`
    mutation AcceptTerms($email: String!, $firstName: String!, $lastName: String!, $organizationName: String!, $title: String!, $url: String!, $version: String!) {
  onboardingAccountAcceptTerms(
    email: $email
    firstName: $firstName
    lastName: $lastName
    organizationName: $organizationName
    title: $title
    url: $url
    version: $version
  ) {
    value {
      accountId
      termAcceptedDateTime
    }
    userErrors {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AcceptTermsGQL extends Apollo.Mutation<AcceptTermsMutation, AcceptTermsMutationVariables> {
    document = AcceptTermsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAdminEmailDocument = gql`
    mutation updateAdminEmail($contractId: String!, $updatedAdminEmail: String!) {
  onboardingContractUpdateAdminEmail(
    contractId: $contractId
    updatedAdminEmail: $updatedAdminEmail
  ) {
    value {
      id
      adminEmail
    }
    userErrors {
      code
      field
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAdminEmailGQL extends Apollo.Mutation<UpdateAdminEmailMutation, UpdateAdminEmailMutationVariables> {
    document = UpdateAdminEmailDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RepublishOnboardAdminEventDocument = gql`
    mutation republishOnboardAdminEvent($contractId: String!) {
  onboardingAccountRepublishAdminEvent(contractId: $contractId) {
    value {
      id
    }
    userErrors {
      code
      field
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RepublishOnboardAdminEventGQL extends Apollo.Mutation<RepublishOnboardAdminEventMutation, RepublishOnboardAdminEventMutationVariables> {
    document = RepublishOnboardAdminEventDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ResendTermEmailDocument = gql`
    mutation ResendTermEmail($contractId: String!) {
  onboardingAccountResendTermEmail(contractId: $contractId) {
    value {
      id
    }
    userErrors {
      code
      field
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ResendTermEmailGQL extends Apollo.Mutation<ResendTermEmailMutation, ResendTermEmailMutationVariables> {
    document = ResendTermEmailDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTermEmailDocument = gql`
    mutation UpdateTermEmail($contractId: String!, $updatedTermEmail: String!) {
  onboardingAccountUpdateTermEmail(
    contractId: $contractId
    updatedTermEmail: $updatedTermEmail
  ) {
    value {
      id
      contactEmail
    }
    userErrors {
      code
      field
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTermEmailGQL extends Apollo.Mutation<UpdateTermEmailMutation, UpdateTermEmailMutationVariables> {
    document = UpdateTermEmailDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AccountAppsDocument = gql`
    query AccountApps {
  currentUser {
    id
    accountApps {
      id
      appKey
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AccountAppsGQL extends Apollo.Query<AccountAppsQuery, AccountAppsQueryVariables> {
    document = AccountAppsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OnboardingContractsDocument = gql`
    query onboardingContracts($contractId: Uuid!) {
  onboardingContracts(where: {id: $contractId}) {
    id
    messageId
    status
    adminEmail
    contactEmail
    hxpContractS3BucketName
    hxpContractS3Key
    sourceContractId
    sourceContractSystem
    type
    contractLog {
      id
      eventDateTime
      userId
      message
    }
    account {
      id
      name
      termEmail
      hxpAccountId
      sourceAccountId
      sourceAccountSystem
    }
    supportAccount {
      id
      name
      sourceAccountId
      sourceAccountSystem
    }
    onboardingSubscriptions {
      id
      title
      hxpEnvironmentId
      hxpEnvironmentTitle
      hxpSubscriptionId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class OnboardingContractsGQL extends Apollo.Query<OnboardingContractsQuery, OnboardingContractsQueryVariables> {
    document = OnboardingContractsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RecentContractsDocument = gql`
    query RecentContracts($createdAfter: Instant, $after: String, $before: String, $first: Int, $last: Int) {
  ContractAndAccountView(
    where: {contractCreatedDateTime: {gt: $createdAfter}}
    after: $after
    before: $before
    first: $first
    last: $last
    order: {contractCreatedDateTime: DESC}
  ) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        accountId
        accountName
        contractCreatedDateTime
        contractId
        contractState
        contractStatus
        contractType
        sourceAccountSystem
        sourceAccountId
        sourceContractSystem
        sourceContractId
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RecentContractsGQL extends Apollo.Query<RecentContractsQuery, RecentContractsQueryVariables> {
    document = RecentContractsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ContractAndAccountViewDocument = gql`
    query ContractAndAccountView($where: ContractAndAccountFilterInput, $after: String, $before: String, $first: Int, $last: Int) {
  ContractAndAccountView(
    where: $where
    after: $after
    before: $before
    first: $first
    last: $last
    order: {contractCreatedDateTime: DESC}
  ) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        accountId
        accountName
        contractCreatedDateTime
        contractId
        contractState
        contractStatus
        sourceAccountSystem
        sourceAccountId
        sourceContractSystem
        sourceContractId
        contractType
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ContractAndAccountViewGQL extends Apollo.Query<ContractAndAccountViewQuery, ContractAndAccountViewQueryVariables> {
    document = ContractAndAccountViewDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CurrentUserAppsDocument = gql`
    query CurrentUserApps {
  currentUser {
    id
    subscribedApps {
      id
      environmentId
      launchUrl
      provisioningStatus
      app {
        id
        localizedName
        appType
      }
    }
    accountApps {
      id
      launchUrl
      provisioningStatus
      app {
        id
        localizedName
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentUserAppsGQL extends Apollo.Query<CurrentUserAppsQuery, CurrentUserAppsQueryVariables> {
    document = CurrentUserAppsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserPortalEnvironmentsDocument = gql`
    query UserPortalEnvironments {
  currentUser {
    id
    subscribedApps {
      id
      environment {
        id
        name
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserPortalEnvironmentsGQL extends Apollo.Query<UserPortalEnvironmentsQuery, UserPortalEnvironmentsQueryVariables> {
    document = UserPortalEnvironmentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AccountAuditEventsDocument = gql`
    query AccountAuditEvents($startDate: Instant!, $endDate: Instant!, $first: Int, $after: String, $before: String, $order: [AuditEventSortInput!]) {
  currentUser {
    id
    homeAccount {
      id
      account {
        id
        auditEvents(
          first: $first
          startDate: $startDate
          endDate: $endDate
          order: $order
          after: $after
          before: $before
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          nodes {
            type
            actor {
              type
              id
            }
            relatedObject {
              id
              scope
            }
            name
            timestamp
            id
          }
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AccountAuditEventsGQL extends Apollo.Query<AccountAuditEventsQuery, AccountAuditEventsQueryVariables> {
    document = AccountAuditEventsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EnvironmentAuditEventsDocument = gql`
    query EnvironmentAuditEvents($id: ID!, $first: Int, $startDate: Instant!, $endDate: Instant!, $order: [AuditEventSortInput!], $after: String, $before: String) {
  currentUser {
    id
    homeAccount {
      id
      account {
        id
        environment(environmentId: $id) {
          auditEvents(
            first: $first
            startDate: $startDate
            endDate: $endDate
            order: $order
            after: $after
            before: $before
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
            nodes {
              type
              actor {
                type
                id
              }
              relatedObject {
                id
                scope
              }
              name
              timestamp
              id
            }
          }
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EnvironmentAuditEventsGQL extends Apollo.Query<EnvironmentAuditEventsQuery, EnvironmentAuditEventsQueryVariables> {
    document = EnvironmentAuditEventsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserAttributesDocument = gql`
    query UserAttributes($after: String, $first: Int) {
  currentUser {
    id
    homeAccount {
      id
      account {
        id
        userAttributes(first: $first, after: $after) {
          nodes {
            key
            displayName
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserAttributesGQL extends Apollo.Query<UserAttributesQuery, UserAttributesQueryVariables> {
    document = UserAttributesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EnvironmentsDocument = gql`
    query Environments {
  currentUser {
    id
    homeAccount {
      id
      account {
        id
        name
        environments {
          id
          name
          key
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EnvironmentsGQL extends Apollo.Query<EnvironmentsQuery, EnvironmentsQueryVariables> {
    document = EnvironmentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SubscribedAppsDocument = gql`
    query SubscribedApps {
  currentUser {
    id
    subscribedApps {
      id
      environmentId
      app {
        id
        key
        appType
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscribedAppsGQL extends Apollo.Query<SubscribedAppsQuery, SubscribedAppsQueryVariables> {
    document = SubscribedAppsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CurrentUserDocument = gql`
    query CurrentUser {
  currentUser {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentUserGQL extends Apollo.Query<CurrentUserQuery, CurrentUserQueryVariables> {
    document = CurrentUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AccountIdDocument = gql`
    query AccountId {
  currentUser {
    id
    homeAccount {
      id
      account {
        id
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AccountIdGQL extends Apollo.Query<AccountIdQuery, AccountIdQueryVariables> {
    document = AccountIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SwitcherAppsDocument = gql`
    query SwitcherApps {
  currentUser {
    id
    accountApps {
      id
      launchUrl
      app {
        id
        localizedName
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SwitcherAppsGQL extends Apollo.Query<SwitcherAppsQuery, SwitcherAppsQueryVariables> {
    document = SwitcherAppsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AccountAppListDocument = gql`
    query AccountAppList {
  currentUser {
    id
    homeAccount {
      id
      account {
        id
        accountApps {
          id
          app {
            id
            localizedName
          }
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AccountAppListGQL extends Apollo.Query<AccountAppListQuery, AccountAppListQueryVariables> {
    document = AccountAppListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RoleUserGroupsDocument = gql`
    query RoleUserGroups {
  currentUser {
    id
    homeAccount {
      id
      account {
        id
        accountApps {
          id
          appKey
          app {
            id
            localizedName
          }
          roles {
            role {
              id: key
              key
              name
            }
            userGroups {
              id
              displayName
            }
          }
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RoleUserGroupsGQL extends Apollo.Query<RoleUserGroupsQuery, RoleUserGroupsQueryVariables> {
    document = RoleUserGroupsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RoleAssignUserGroupsForAccountDocument = gql`
    mutation RoleAssignUserGroupsForAccount($input: RoleAssignUserGroupsForAccountInput!) {
  roleAssignUserGroupsForAccount(input: $input) {
    value
    userErrors {
      code
      field
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RoleAssignUserGroupsForAccountGQL extends Apollo.Mutation<RoleAssignUserGroupsForAccountMutation, RoleAssignUserGroupsForAccountMutationVariables> {
    document = RoleAssignUserGroupsForAccountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AccountDetailsOverviewDocument = gql`
    query AccountDetailsOverview {
  currentUser {
    id
    homeAccount {
      id
      account {
        id
        name
        hsiNumber
        sourceAccountSystem
        sourceAccountId
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AccountDetailsOverviewGQL extends Apollo.Query<AccountDetailsOverviewQuery, AccountDetailsOverviewQueryVariables> {
    document = AccountDetailsOverviewDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AccountDetailSubscriptionsDocument = gql`
    query AccountDetailSubscriptions {
  currentUser {
    id
    homeAccount {
      id
      account {
        id
        subscriptions {
          id
          title
          status
          type
          environment {
            id
            name
          }
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AccountDetailSubscriptionsGQL extends Apollo.Query<AccountDetailSubscriptionsQuery, AccountDetailSubscriptionsQueryVariables> {
    document = AccountDetailSubscriptionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AccountDetailsDocument = gql`
    query AccountDetails {
  currentUser {
    id
    homeAccount {
      id
      account {
        id
        name
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AccountDetailsGQL extends Apollo.Query<AccountDetailsQuery, AccountDetailsQueryVariables> {
    document = AccountDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ApplicationDetailsDocument = gql`
    query ApplicationDetails($id: ID!) {
  currentUser {
    id
    homeAccount {
      id
      account {
        id
        subscribedApp(id: $id) {
          id
          environment {
            id
            name
          }
          app {
            id
            localizedName
            appType
          }
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApplicationDetailsGQL extends Apollo.Query<ApplicationDetailsQuery, ApplicationDetailsQueryVariables> {
    document = ApplicationDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AppUserRightsDocument = gql`
    query AppUserRights($id: ID!) {
  currentUser {
    id
    homeAccount {
      id
      account {
        id
        subscribedApp(id: $id) {
          id
          appKey
          environmentRoles {
            role {
              id: key
              key
              name
            }
            userGroups {
              id
              displayName
            }
          }
          environment {
            id
          }
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AppUserRightsGQL extends Apollo.Query<AppUserRightsQuery, AppUserRightsQueryVariables> {
    document = AppUserRightsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RoleAssignUserGroupsForEnvironmentDocument = gql`
    mutation RoleAssignUserGroupsForEnvironment($input: RoleAssignUserGroupsForEnvironmentInput!) {
  roleAssignUserGroupsForEnvironment(input: $input) {
    value
    userErrors {
      code
      field
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RoleAssignUserGroupsForEnvironmentGQL extends Apollo.Mutation<RoleAssignUserGroupsForEnvironmentMutation, RoleAssignUserGroupsForEnvironmentMutationVariables> {
    document = RoleAssignUserGroupsForEnvironmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ApplicationOverviewDocument = gql`
    query ApplicationOverview($id: ID!) {
  currentUser {
    id
    homeAccount {
      id
      account {
        id
        subscribedApp(id: $id) {
          id
          subscription {
            id
            title
          }
          app {
            id
            localizedName
            localizedDescription
          }
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApplicationOverviewGQL extends Apollo.Query<ApplicationOverviewQuery, ApplicationOverviewQueryVariables> {
    document = ApplicationOverviewDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EnvironmentApplicationListDocument = gql`
    query EnvironmentApplicationList($id: ID!) {
  currentUser {
    id
    homeAccount {
      id
      account {
        id
        environment(environmentId: $id) {
          id
          name
          subscriptions {
            id
            title
            status
            subscribedApps {
              id
              app {
                id
                key
                localizedName
                appType
              }
            }
          }
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EnvironmentApplicationListGQL extends Apollo.Query<EnvironmentApplicationListQuery, EnvironmentApplicationListQueryVariables> {
    document = EnvironmentApplicationListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SubscriptionsForAccountDocument = gql`
    query SubscriptionsForAccount {
  currentUser {
    id
    homeAccount {
      id
      account {
        id
        subscriptions {
          id
          title
          subscribedApps {
            id
            appKey
            app {
              id
              localizedName
              appType
            }
          }
          status
          environment {
            id
            name
          }
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscriptionsForAccountGQL extends Apollo.Query<SubscriptionsForAccountQuery, SubscriptionsForAccountQueryVariables> {
    document = SubscriptionsForAccountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SubscriptionForPollingDocument = gql`
    query SubscriptionForPolling($id: ID!) {
  subscriptionById(id: $id) {
    id
    title
    status
    subscribedApps {
      id
      provisioningStatus
    }
    subscribedAccountApps {
      id
      provisioningStatus
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscriptionForPollingGQL extends Apollo.Query<SubscriptionForPollingQuery, SubscriptionForPollingQueryVariables> {
    document = SubscriptionForPollingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EnvironmentCreateDocument = gql`
    mutation environmentCreate($accountId: ID!, $description: String, $name: String!) {
  environmentCreate(accountId: $accountId, description: $description, name: $name) {
    userErrors {
      code
      field
      message
    }
    value {
      id
      name
      accountId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EnvironmentCreateGQL extends Apollo.Mutation<EnvironmentCreateMutation, EnvironmentCreateMutationVariables> {
    document = EnvironmentCreateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SubscriptionOverviewDocument = gql`
    query SubscriptionOverview($id: ID!) {
  subscriptionById(id: $id) {
    id
    decommissionedDate
    endDate
    startDate
    status
    title
    type
    environment {
      id
      name
    }
    subscribedApps {
      id
      provisioningStatus
      app {
        id
        localizedName
        localizedDescription
        appType
      }
    }
    subscribedAccountApps {
      id
      provisioningStatus
      app {
        id
        localizedName
        localizedDescription
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscriptionOverviewGQL extends Apollo.Query<SubscriptionOverviewQuery, SubscriptionOverviewQueryVariables> {
    document = SubscriptionOverviewDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SubscriptionOverviewEnvironmentsDocument = gql`
    query SubscriptionOverviewEnvironments($id: ID!) {
  subscriptionById(id: $id) {
    id
    contract {
      account {
        id
        environments {
          id
          name
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscriptionOverviewEnvironmentsGQL extends Apollo.Query<SubscriptionOverviewEnvironmentsQuery, SubscriptionOverviewEnvironmentsQueryVariables> {
    document = SubscriptionOverviewEnvironmentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SetSubscriptionEnvironmentDocument = gql`
    mutation SetSubscriptionEnvironment($subscriptionId: ID!, $environmentId: ID!) {
  subscriptionActivateWithEnvironment(
    id: $subscriptionId
    environmentId: $environmentId
  ) {
    userErrors {
      code
      field
      message
    }
    value {
      id
      environment {
        id
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetSubscriptionEnvironmentGQL extends Apollo.Mutation<SetSubscriptionEnvironmentMutation, SetSubscriptionEnvironmentMutationVariables> {
    document = SetSubscriptionEnvironmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SubscriptionDetailsDocument = gql`
    query SubscriptionDetails($id: ID!) {
  subscriptionById(id: $id) {
    id
    title
    environment {
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscriptionDetailsGQL extends Apollo.Query<SubscriptionDetailsQuery, SubscriptionDetailsQueryVariables> {
    document = SubscriptionDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SubscriptionListDocument = gql`
    query SubscriptionList($environmentId: ID!) {
  currentUser {
    id
    homeAccount {
      id
      account {
        id
        environment(environmentId: $environmentId) {
          id
          name
          subscriptions {
            id
            title
            type
            status
          }
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscriptionListGQL extends Apollo.Query<SubscriptionListQuery, SubscriptionListQueryVariables> {
    document = SubscriptionListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SubscriptionUsageDocument = gql`
    query SubscriptionUsage($id: ID!) {
  subscriptionById(id: $id) {
    id
    currentCycleBillableUsage {
      descriptionLocalizationKey
      nameLocalizationKey
      pricingStrategy
      strategy {
        ... on Allotment {
          allotmentSize
          overageCycleCount
        }
        cycleCount
        cyclePeriod
        cyclePeriodEndDate
        cyclePeriodStartDate
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscriptionUsageGQL extends Apollo.Query<SubscriptionUsageQuery, SubscriptionUsageQueryVariables> {
    document = SubscriptionUsageDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "IPricingStrategy": [
      "Allotment",
      "Tiered"
    ]
  }
};
      export default result;
    